import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Table,
  TreeSelect
} from 'antd'
import axios from 'axios'
import React, { useCallback, useRef, useState } from 'react'
import { useEffect } from 'react'
import QRCode from 'react-qr-code'
import { QRCodeCanvas } from 'qrcode.react'
import jsPDF from 'jspdf'
import { CSSTransition } from 'react-transition-group'
import { PrimaryButton, SecondaryButton } from '../Buttons'
import logo from '../../../images/logo_agrosuper_azul.png'
import { EyeCloseSvgIcon, FilledCircleIcon } from '../Icons/Icons'

export const ModalsAcepted = (props) => {
  return (
    <Modal
      title='Modal'
      open={props.open}
      onOk={() => {}}
      onCancel={() => {}}
      okText='确认'
      cancelText='坖消'
    ></Modal>
  )
}

export const AlertModal = (props) => {
  const [icon, setIcon] = React.useState({
    success: <CheckCircleFilled style={{ color: '#14C159' }} />,
    error: <CloseCircleFilled style={{ color: '#E82525' }} />
  })

  return (
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className='modal' onClick={props.onClose}>
        <div className='modal-content' onClick={(e) => e.stopPropagation()}>
          <div
            className={`modal-header ${
              props.type === 'success' ? 'modal-success' : 'modal-error'
            }`}
          >
            <div
              className={`modal-icon ${
                props.type === 'success'
                  ? 'modal-icon_success'
                  : 'modal-icon_error'
              }`}
            >
              {icon[props.type]}
            </div>
            <h4 className='modal-title'>{props.title}</h4>
          </div>
          <div
            className={`modal-body ${
              props.type === 'success'
                ? 'modal-success modal-body_success'
                : 'modal-error modal-body_error'
            }`}
          >
            {props.type === 'success' ? props.children : null}
            {props.type === 'error' ? props.children : null}
            {/* {props.type === "error" && !props.errorMessages ? (
              <p>Ha ocurrido un error al realizar la operación.</p>
            ) : null} */}
          </div>
          <div
            className={`modal-footer ${
              props.type === 'success' ? 'modal-success' : 'modal-error'
            }`}
          >
            <PrimaryButton onButtonClick={props.onConfirm || props.onClose}>
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export const ConfirmModal = ({
  record,
  children,
  show,
  onClose,
  title,
  url,
  method,
  data
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const onConfirm = async (url, method) => {
    setIsLoading(true)
    try {
      await axios({
        method: method,
        url: url + record.id.toString(),
        data: data
      })
      setIsLoading(false)
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  if (!record) return null
  return (
    <CSSTransition in={show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
      <div className='confirm-modal' onClick={onClose}>
        <div
          className='confirm-modal-content'
          onClick={(e) => e.stopPropagation()}
        >
          <div className='confirm-modal-header'>
            <h4 className='confirm-modal-title'>{title}</h4>
          </div>
          <div className='confirm-modal-body'>{children}</div>
          <div className='confirm-modal-footer'>
            <SecondaryButton width={150} onButtonClick={onClose}>
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              isLoading={isLoading}
              width={150}
              onButtonClick={() => onConfirm(url, method)}
            >
              Aceptar
            </PrimaryButton>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export const DeleteModal = ({
  title,
  onClose,
  onConfirm,
  show,
  messageModal,
  isLoading,
  structureName,
  needsConfirm
}) => {
  const [isChecked, setIsChecked] = React.useState(false)

  console.log('isLoading al momento de levantar ',isLoading);



  useEffect(() => {
    if (!show) {
      setIsChecked(false)
    }
  }, [show])
  return (
    <Modal
      title={title}
      centered
      open={show}
      // onOk={onOk}
      onCancel={onClose}
      footer={null}
    >
      <div className='delete-modal-wrapper'>
        <div className='delete-modal-body'>
          <span style={{ fontWeight: '600' }}>{structureName}</span>
          {messageModal}
        </div>
        <div className='modal-confirm'>
          {needsConfirm ? (
            <Checkbox
              onChange={(e) => setIsChecked(e.target.checked)}
              checked={isChecked}
            >
              <span style={{ fontWeight: '600' }}>
                {' '}
                Estoy de acuerdo con la acción aplicada y deseo continuar con el
                proceso de Eliminación/Desactivación.
              </span>
            </Checkbox>
          ) : null}
        </div>
        <div className='delete-modal-footer'>
          <SecondaryButton width={150} onButtonClick={onClose}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton
            isLoading={false}
            width={150}
            onButtonClick={onConfirm}
            disabled={needsConfirm ? !isChecked : false}
          >
            Aceptar
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}

export const ChangeStatusModal = ({
  title,
  show,
  onClose,
  onConfirm,
  messageModal,
  isLoading,
  structureName,
  needsConfirm,
  ...props
}) => {
  const [isChecked, setIsChecked] = React.useState(false)

  useEffect(() => {
    if (!show) {
      setIsChecked(false)
    }
  }, [show])

  return (
    <Modal
      title={title}
      centered
      open={show}
      // onOk={onOk}
      onCancel={onClose}
      footer={null}
    >
      <div className='delete-modal-wrapper'>
        <div className='delete-modal-body'>
          <span style={{ fontWeight: '600' }}>{structureName}</span>
          {messageModal}
        </div>
        <div className='modal-confirm'>
          {needsConfirm ? (
            <Checkbox
              onChange={(e) => setIsChecked(e.target.checked)}
              checked={isChecked}
            >
              <span style={{ fontWeight: '600' }}>
                {' '}
                Estoy de acuerdo con la acción aplicada y deseo continuar con el
                proceso de Eliminación/Desactivación.
              </span>
            </Checkbox>
          ) : null}
        </div>
        <div className='delete-modal-footer'>
          <SecondaryButton width={150} onButtonClick={onClose}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton
            isLoading={isLoading}
            width={150}
            disabled={needsConfirm ? !isChecked : false}
            onButtonClick={onConfirm}
          >
            Aceptar
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}

export const PopupModal = ({
  record,
  children,
  open,
  onClose,
  title,
  url,
  method,
  data,
  onFetchClose
}) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const onConfirm = async (url, method) => {
    setIsLoading(true)
    try {
      await axios({
        method: method,
        url: url + record.id.toString(),
        data: data
      })
      setIsLoading(false)
      onFetchClose()
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <Modal
      title={title}
      centered
      open={open}
      // onOk={onOk}
      onCancel={onClose}
      footer={null}
    >
      <div className='confirm-modal-wrapper'>
        <div className='confirm-modal-body'>{children}</div>
        <div className='confirm-modal-footer'>
          <SecondaryButton width={150} onButtonClick={onClose}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton
            isLoading={isLoading}
            width={150}
            onButtonClick={() => onConfirm(url, method)}
          >
            Aceptar
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}

export const PopupModalQR = ({
  open,
  onClose,
  title,
  invalidar,
  loadingInvalidte,
  jwt,
  fechaCaducidad,
  setShowModalSendQR,
  dataPerson
}) => {
  const qrRef = useRef()
  const [qrValue, setQrValue] = useState(null)
  const [identificador, setIdentificador] = useState(0)

  const downloadQRPdf = () => {
    const canvas = qrRef.current?.children?.[0]

    if (canvas) {
      const imgData = canvas.toDataURL('img/png')

      const pdf = new jsPDF('p', 'px', 'letter')
      //calcular ancho de hoja
      const pageWidth =
        pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth()

      //calcula la posicion de la imagen de acuerdo a su ancho y la hoja
      const marginX = (pageWidth - 150) / 2

      //agrega logo

      // pdf.addImage(imagen, formato, positionX, positionY, width, height, {
      //   align: alineacion,
      // });

      pdf.addImage(logo, 'PNG', 5, 20, 100, 22, {
        align: 'start'
      })

      //se agrega imagen qr
      pdf.addImage(imgData, 'PNG', marginX, 90, 150, 150, {
        align: 'center'
      })

      // se agrega nombre de usuario al final del qr
      pdf.text(
        dataPerson?.name + ' ' + dataPerson?.lastname,
        pageWidth / 2,
        260,
        {
          align: 'center'
        }
      )

      pdf.save(`QR-Code-${identificador}.pdf`)
    }
  }

  const onSendEmail = () => {
    //enviar por correo
    onClose()
    setShowModalSendQR(true)
  }

  useEffect(() => {
    const getTokenValidity = async (jwtToken) => {
      try {
        const urlValidate =
          process.env.REACT_APP_BASE_URL_MOVEMENTS +
          '/movements-user/validate-Qr'
        const response = await axios.get(urlValidate + '?token=' + jwtToken)
        
        if (!response.data || !response.data?.type_identificador) {
          setQrValue(null)
        }

        
        const type =
          response.data.type_identificador === 1 ? 'cedula' : 'passport'
        const url = `https://portal.sidiv.registrocivil.cl/docstatus?RUN=${response.data.identificador}&type=${type}&serial=&token=${jwtToken}`

        setQrValue(url)
        setIdentificador(response.data.identificador)
      } catch (error) {
        setQrValue(null)
      }
    }
    if (jwt) getTokenValidity(jwt)
  }, [jwt])

  return (
    <Modal
      title={title}
      centered
      open={open}
      // onOk={onOk}
      onCancel={onClose}
      footer={null}
    >
      <div className='confirm-modal-wrapper modal-qr'>
        <div className='confirm-modal-body'>
          <div>
            {qrValue ? (
              <div ref={qrRef}>
                <QRCodeCanvas value={qrValue} size={256} />
              </div>
            ) : null}
            {fechaCaducidad !== '' ? (
              <p>
                El QR generado tiene vigencia hasta la fecha de {fechaCaducidad}
                .
              </p>
            ) : (
              <p>El QR generado tiene vigencia de manera permanente.</p>
            )}
          </div>
        </div>
        <div className='confirm-modal-footer confirm-modal-botonera'>
          <PrimaryButton width={150} onButtonClick={downloadQRPdf}>
            Descargar
          </PrimaryButton>
          <PrimaryButton width={150} onButtonClick={onSendEmail}>
            Enviar al correo
          </PrimaryButton>
          <SecondaryButton
            isLoading={loadingInvalidte}
            width={150}
            onButtonClick={invalidar}
          >
            Invalidar
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  )
}

export const PopupModalQRGeneracion = ({
  prueba,
  open,
  onClose,
  title,
  form,
  loadings
}) => {
  const [typeView, settypeView] = useState('0')
  const handleType = (e) => {
    settypeView(e.target.value)
    form.setFieldsValue({ fecha_caducidad: undefined })
  }

  return (
    <Modal title={title} centered open={open} onCancel={onClose} footer={null}>
      <div
        style={{
          alignItems: 'center'
        }}
      >
        <div className=''>
          <Form layout='vertical' form={form}>
            <Form.Item
              name='caducidad'
              rules={[
                {
                  required: typeView === '1' ? true : false
                }
              ]}
            >
              <Radio.Group onChange={handleType} defaultValue={typeView}>
                <Radio value='1'>Fecha caducidad</Radio>
                <Radio value='0'>Sin caducidad</Radio>
              </Radio.Group>
            </Form.Item>
            {typeView === '1' ? (
              <Form.Item label='Fecha' name='fecha_caducidad'>
                <DatePicker />
              </Form.Item>
            ) : null}
          </Form>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '5px'
          }}
        >
          <SecondaryButton width={150} onButtonClick={onClose}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton
            isLoading={loadings}
            width={150}
            onButtonClick={prueba}
          >
            Generar
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}

export const PopupModalQRSendEmail = ({
  prueba,
  open,
  onClose,
  title,
  form,
  loadings
}) => {
  return (
    <Modal title={title} centered open={open} onCancel={onClose} footer={null}>
      <div
        style={{
          alignItems: 'center'
        }}
      >
        <div className=''>
          <Form layout='vertical' form={form}>
            <Form.Item
              name='email'
              label='Email'
              rules={[
                {
                  type: 'email'
                }
              ]}
            >
              <Input placeholder='Ingrese Email de destino' />
            </Form.Item>
          </Form>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '5px'
          }}
        >
          <SecondaryButton width={150} onButtonClick={onClose}>
            Cancelar
          </SecondaryButton>
          <PrimaryButton
            isLoading={loadings}
            width={150}
            onButtonClick={prueba}
          >
            Enviar
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}

export const PopupModalInvalidarCuarentena = ({
  prueba,
  open,
  onClose,
  loadings
}) => {
  return (
    <Modal
      title={null}
      centered
      open={open}
      visible={open}
      // onOk={onOk}
      onCancel={onClose}
      footer={null}
      width={850}
    >
      <div className='confirm-modal-wrapper modal-invalidar-cuarentena'>
        <div className='confirm-modal-body'>
          <div>
            <p>
              <strong>Invalidar cuarentena</strong>
            </p>
            <p>
              ¡Importante! Al marcar el “check”, invalidará la cuarentena de
              todos los colaboradores afectados que se encuentren en el listado.
            </p>
            <p>
              Si no marcas “check”, solo se invalidará para ingresos futuros y
              los colaboradores ya afectados deberán cumplir con sus noches de
              vacío restante.
            </p>
            <Checkbox>
              <p className='declaracion-visitas__text-ckeck'>
                Colaboradores afectados
              </p>
            </Checkbox>
          </div>
        </div>
        <div className='confirm-modal-footer confirm-modal-botonera'>
          <SecondaryButton onButtonClick={onClose} width={150}>
            Cerrar
          </SecondaryButton>
          <PrimaryButton
            onButtonClick={prueba}
            isLoading={loadings}
            width={230}
          >
            Invalidad cuarentena
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}

export const ModalInvalidarExcepcion = ({
  open,
  onclose,
  excepcionData,
  setShowModal,
  setModalInfo
}) => {
  const [exceptionID, setExceptionID] = useState(0)
  const [specieCheck, setSpecieCheck] = useState([])
  const [foodPlantCheck, setFoodPlantCheck] = useState([])
  const [isLoadingInvalidar, setIsLoadingInvalidar] = useState(false)
  const [CheckGroup, setCheckGroup] = useState([])
  const [optionsCheck, setOptionsCheck] = useState()

  useEffect(() => {
    const arrayOptions = []
    excepcionData?.exceptionClasifications
      ?.filter((excep) => excep.specie.code !== '004')
      .map((element) => {
        arrayOptions.push(element)
      })
    const findPlant = excepcionData?.exceptionClasifications?.find(
      (excep) => excep.specie.code === '004'
    )

    if (findPlant) {
      arrayOptions.push(findPlant)
    }
    setOptionsCheck(
      arrayOptions?.map((e) => {
        return {
          label: e.specie.name_specie,
          value: e.specie.id,
          key: e.specie.id
        }
      })
    )

    const filter = excepcionData?.exceptionClasifications
      ?.filter((exception) => exception.status_id === 11)
      .map((elem) => elem.specie.id)
    setCheckGroup(filter)
  }, [excepcionData])

  const onChange = (event) => {
    setCheckGroup(event)
    const filter = excepcionData?.exceptionClasifications?.filter((excep) =>
      event.includes(excep.specie?.id)
    )

    const arrSpecies = []
    const arrFoodPlants = []

    if (event.includes(1)) {
      arrFoodPlants.push(...filter.map((specie) => specie.foodPlant?.id))
    }

    filter.map((specie) => {
      arrSpecies.push(specie.specie?.id)
      setExceptionID(specie.exception_id)
    })

    const uniqueValue = [...new Set(arrSpecies)]

    setSpecieCheck(uniqueValue)
    setFoodPlantCheck(arrFoodPlants)
  }

  const invalidar = async () => {
    setIsLoadingInvalidar(true)
    const url = process.env.REACT_APP_BASE_URL_MOVEMENTS + '/exceptions/disable'

    let body = {
      exception_id: exceptionID,
      specie_exception: specieCheck
    }

    if (foodPlantCheck.length > 0) {
      body.foodPlant = foodPlantCheck
    }

    await axios
      .post(url, body)
      .then((response) => {
        setModalInfo({
          type: 'success',
          title: 'Excepción invalidada',
          message: 'Excepción invalidada correctamente'
        })
      })
      .catch((e) =>
        setModalInfo({
          type: 'error',
          title: 'Error',
          message: 'La excepción no ha podido ser invalidada'
        })
      )
      .finally(() => {
        onclose()
        setIsLoadingInvalidar(false)
        setShowModal(true)
        setSpecieCheck([])
        setFoodPlantCheck([])
        setExceptionID(0)
        setCheckGroup([])
      })
  }

  return (
    <Modal
      open={open}
      centered
      onCancel={onclose}
      title='Invalidar excepción'
      footer={null}
      width={500}
    >
      <div className='card-invalidate-exception'>
        <div>
          <div>
            <Row>
              <Col span={24} className='col-span-model-exception'>
                <span>Seleccione una estructura para invalidar</span>
              </Col>
              <br />
              <Col>
                <Checkbox.Group
                  options={optionsCheck}
                  value={CheckGroup}
                  onChange={onChange}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className='footer_modal_exception'>
          <SecondaryButton onButtonClick={onclose} width={120}>
            Cerrar
          </SecondaryButton>
          <PrimaryButton
            onButtonClick={invalidar}
            isLoading={isLoadingInvalidar}
            width={120}
          >
            Invalidar
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}

export const ModalVerPermisosExcepcion = ({
  open,
  onclose,
  loading,
  excepcionData
}) => {
  const [dataPermissions, setDataPermissions] = useState([])
  const [isFoodPlant, setIsFoodPlant] = useState(false)

  const columns = [
    {
      title: 'Nivel',
      dataIndex: ['level'],
      render: (record) => record.name
    },
    {
      title: 'Zona',
      dataIndex: ['accessZonesException'],
      render: (record) =>
        record.map((rec) => {
          return <Row>{rec.zone.name}</Row>
        })
    },
    {
      title: 'Sector',
      dataIndex: ['accessZonesException'],
      render: (record) =>
        record.map((rec) => {
          return rec.accessSectorsExceptions.map((recSectores) => {
            return <Row>{recSectores.sector?.name}</Row>
          })
        })
    }
  ]

  const statusCodes = {
    1: 'Activo',
    2: 'Denegado',
    3: 'Pendiente',
    4: 'Aprobado',
    5: 'Denegado',
    7: 'Aprobado',
    8: 'Denegado',
    9: 'Finalizado',
    10: 'Aprobado',
    11: 'Invalida'
  }

  const columnsPlants = [
    {
      title: 'Nombre de planta',
      dataIndex: ['plants'],
      render: (record) => record[0].name
    },
    {
      title: 'Estado',
      dataIndex: ['status'],
      render: (record) => statusCodes[record.id]
    }
  ]
  //aqui
  const getData = useCallback(() => {
    let dataArr = []
    excepcionData?.exceptionClasifications?.map((ClasificationException) => {
      let obj = {
        status: ClasificationException.status?.name
      }

      if (ClasificationException.specie.code !== '004') {
        obj.specie_name = ClasificationException.specie?.name_specie
        obj.accessLevelExceptions = ClasificationException.accessLevelExceptions
        setIsFoodPlant(false)
      } else {
        // obj.plants.permission = "Aceptado";
        setIsFoodPlant(true)
        obj.specie_name = 'Planta de alimentos'
        obj.plants = [ClasificationException.foodPlant]
        obj.status = ClasificationException.status
      }

      dataArr.push(obj)
    })

    setDataPermissions(dataArr)
  }, [excepcionData])

  const nameStatus = (name) => {
    let nameFinal

    switch (name) {
      case 'PENDING':
        nameFinal = 'Pendiente'
        break
      case 'ACTIVE':
        nameFinal = 'Activo'
        break
      case 'DISABLE':
        nameFinal = 'Inactivo'
        break
      case 'FINISHED':
        nameFinal = 'Finalizado'
        break
      case 'INVALIDATED':
        nameFinal = 'Invalida'
        break
      default:
        break
    }

    return nameFinal
  }

  const colorStatus = (status) => {
    let color

    switch (status) {
      case 'PENDING':
        color = 'rgba(250, 219, 20, 1)'
        break
      case 'ACTIVE':
        color = 'rgba(20, 193, 89, 1)'
        break
      case 'INACTIVE':
        color = '#FF4D4F'
        break

      case 'FINISHED':
        color = '#BFBFBF'
        break
      case 'DISABLE':
        color = '#E82525'
        break
      case 'INVALIDATED':
        color = '#E82525'
        break
      default:
        break
    }

    return color
  }

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <Modal
      open={open}
      centered
      onCancel={onclose}
      title='Detalle de registros'
      footer={null}
      width={700}
    >
      <div className='card-invalidate-exception'>
        <div className=''>
          <div>
            {isFoodPlant ? (
              <Card
                title={`Permisos a Planta de alimentos`}
                extra={
                  <div style={{ display: 'flex', columnGap: '5px' }}>
                    <div>
                      <FilledCircleIcon
                        color={colorStatus(excepcionData.status.name)}
                        width='10px'
                      />
                    </div>
                    <div>{nameStatus(excepcionData.status.name)}</div>
                  </div>
                }
              >
                <Table
                  columns={columnsPlants}
                  dataSource={dataPermissions}
                  pagination={false}
                />
              </Card>
            ) : (
              <>
                {dataPermissions?.map((data) => {
                  return (
                    <Card
                      title={`Permisos a ${data.specie_name}`}
                      extra={
                        <div style={{ display: 'flex', columnGap: '5px' }}>
                          <div>
                            <FilledCircleIcon
                              color={colorStatus(data.status)}
                              width='10px'
                            />
                          </div>
                          <div>{nameStatus(data.status)}</div>
                        </div>
                      }
                    >
                      <Table
                        columns={columns}
                        dataSource={data.accessLevelExceptions}
                        pagination={false}
                      />
                    </Card>
                  )
                })}
              </>
            )}
          </div>
        </div>
        <div style={{ marginTop: '24px' }}>
          <SecondaryButton onButtonClick={onclose} width={120}>
            Cerrar
          </SecondaryButton>
        </div>
      </div>
    </Modal>
  )
}
