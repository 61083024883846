import React from "react";
import FormNuevaCuarentena from "./FormNuevaCuarentena";
import CrearCuarentena from "./CrearCuarentena";
import SectoresAfectados from "./SectoresAfectados";
import RegistroExepciones from "./RegistroExepciones";

const Stepper = ({
  tipoVisita,
  current,
  form,
  setSelectedSpecie,
  selectedSpecie,
  setDataForm,
  dataForm,
  specie,
  isLoading,
  setSelectedDates,
  selectedDates,
  setSelectedSector,
  selectedSector,
  setSelectedRows,
  selectedRows,
  errorSpecieSelected,
  seterrorSpecieSelected,
  isValidSector,
  formData,
  setAllSectors,
  allSectors,
  setColaboradores,
  colaboradores,
}) => {
  return (
    <>
      {current === 0 && (
        <FormNuevaCuarentena
          form={form}
          setSelectedSpecie={setSelectedSpecie}
          selectedSpecie={selectedSpecie}
          setDataForm={setDataForm}
          dataForm={dataForm}
          specie={specie}
          isLoading={isLoading}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
          setSelectedSector={setSelectedSector}
          selectedSector={selectedSector}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          errorSpecieSelected={errorSpecieSelected}
          seterrorSpecieSelected={seterrorSpecieSelected}
        />
      )}
      {current === 1 && (
        <CrearCuarentena
          form={form}
          selectedSpecie={selectedSpecie}
          colaboradores={colaboradores}
          setColaboradores={setColaboradores}
        />
      )}
      {current === 2 && (
        <SectoresAfectados
          form={form}
          selectedSector={selectedSector}
          selectedSpecie={selectedSpecie}
          current={current}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          isValidSector={isValidSector}
          setAllSectors={setAllSectors}
          allSectors={allSectors}
        />
      )}

      {current === 3 && (
        <RegistroExepciones
          form={form}
          selectedSpecie={selectedSpecie}
          selectedSector={selectedSector}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
        />
      )}
    </>
  );
};

export default Stepper;
