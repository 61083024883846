import { LeftCircleFilled } from "@ant-design/icons";
import { Divider, Tabs } from "antd";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DatosCuarentena from "./DatosCuarentena";
import TablaRegistroSectoresCuarentena from "components/TableContainer/Cuarentenas/RegistroSectores";
import TablaRegistroColaboradoresCuarentena from "components/TableContainer/Cuarentenas/RegistroColaboradores";
import {
  AlertModal,
  PopupModalInvalidarCuarentena
} from 'components/common/modals/modals'

function VerDetalleCuarentena() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { cuarentenaId, invalidar } = state;
  const [cuarentenaInfo, setCuarentenaInfo] = React.useState(null);
  const [stats, setStats] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [sectors, setSectors] = React.useState([]);
  const [colaboradores, setColaboradores] = React.useState([]);
  const [sectorId, setSectorId] = React.useState([]);

  const [showConfirmModal, setShowConfirmModal] = React.useState()
  const [loadings, setLoadings] = React.useState(false)
  const [showResultInvalidate, setResultInvalidate] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });

  const getQuarantineDetails = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/${cuarentenaId}`
      );

      setCuarentenaInfo(response.data.quarantines);
      setSectors(response.data.sectors_until);
      setSectorId(response.data.quarantines.sector_id);
      setColaboradores(response.data.quarantines.typePersonalsAccessQuarantines);
      setLoading(false);

      if (invalidar) setShowConfirmModal(true);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };


  const invaldiateQuarantine = async () => {
    const urlInvalidate =
    `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/${cuarentenaId}`
    setLoadings(true)

    const userExceptionData = cuarentenaInfo.quarantineUserExceptions.map((item) => ({
      user_id: item.user_id,
      check: true,
    }));

    let payload = {
      user_exception_in_quarantine: userExceptionData,
    }
    
    await axios
      .delete(urlInvalidate, {data: payload})
      .then((response) => {
        setLoadings(false)
        setShowConfirmModal(false)
        if (response.status === 200 || response.status === 201) {
          setModalInfo({
            open: true,
            type: "success",
            title: "Cuarentena Invalidada",
            message: "La cuarentena se ha invalidado correctamente",
          });
        }


      })
      .catch((error) => {
        setModalInfo({
          type: "error",
          title: "¡Error!",
          message: "Ha ocurrido un error al invalidar la cuarentena",
        });
        console.log(error)
      })
      .finally(() => {
        setLoadings(false);
        setResultInvalidate(true);
      });


  }


  const closeConfirmModal = () => {
    setShowConfirmModal(false)
  }

  useEffect(() => {
    getQuarantineDetails();
  }, []);

  return (
    <>
    <div className="detalle-cuarentena-container">
      <div className="volver" onClick={() => navigate(-1)}>
        <LeftCircleFilled />
        <span style={{ fontWeight: "600" }}>Cuarentenas</span>
      </div>
      <Divider />
      <div className="cuarentena-container">
        <section className="cuarentena-info-container">
          <DatosCuarentena loading={loading} datos={cuarentenaInfo} />
        </section>
        <section className="cuarentena-tables">
          <Tabs defaultActiveKey="1" type="card" tabBarGutter={16}>
            
            <Tabs.TabPane tab="Colaboradores" key="2">
              <TablaRegistroColaboradoresCuarentena
                loading={loading}
                cuarentenaInfo={cuarentenaInfo}
                colaboradores={colaboradores}
                cuarentenaId={cuarentenaId}
              />
            </Tabs.TabPane>
          </Tabs>
        </section>
      </div>
      
      <PopupModalInvalidarCuarentena
        title='Invalidar cuarentena'
        open={showConfirmModal} 
        visible={showConfirmModal}
        prueba={invaldiateQuarantine}
        onClose={closeConfirmModal}
        loadings={loadings}
      />
      <AlertModal
        show={showResultInvalidate}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => navigate("/cuarentenas")}
      >{modalInfo.message} </AlertModal>
    </div>
    
    </>
  );
}

export default VerDetalleCuarentena;
