import React from "react";
import RegistroExepcionesEditar from "./RegistroExepcionesEditar";
import SectoresAfectadosEditar from "./SectoresAfectadosEditar";
import EditarCuarentena from "./EditarCuarentena";
import FormEditarCuarentena from "./FormEditarCuarentena";
import { Row } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const StepperEditar = ({
  tipoVisita,
  current,
  form,
  setSelectedSpecie,
  selectedSpecie,
  setDataForm,
  dataForm,
  specie,
  isLoading,
  setSelectedDates,
  selectedDates,
  setSelectedSector,
  selectedSector,
  setSelectedRows,
  selectedRows,
  errorSpecieSelected,
  seterrorSpecieSelected,
  isValidSector,
  dataDetails,
  formData,
  setAllSectors,
  allSectors,
  setColaboradores,
  colaboradores,
  removeColaboradores,
  setRemoveColaboradores,
}) => {
  return (
    <>
      {current === 0 && (
        <FormEditarCuarentena
          form={form}
          setSelectedSpecie={setSelectedSpecie}
          selectedSpecie={selectedSpecie}
          setDataForm={setDataForm}
          dataForm={dataForm}
          specie={specie}
          isLoading={isLoading}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
          setSelectedSector={setSelectedSector}
          selectedSector={selectedSector}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          errorSpecieSelected={errorSpecieSelected}
          seterrorSpecieSelected={seterrorSpecieSelected}
          dataDetails={dataDetails}
          formData={formData}
        />
      )}
      {current === 1 && (
        <EditarCuarentena
          form={form}
          selectedSpecie={selectedSpecie}
          dataDetails={dataDetails}
          colaboradores={colaboradores}
          setColaboradores={setColaboradores}
          removeColaboradores={removeColaboradores}
          setRemoveColaboradores={setRemoveColaboradores}
        />
      )}
      {current === 2 && (
        <SectoresAfectadosEditar
          form={form}
          selectedSector={selectedSector}
          selectedSpecie={selectedSpecie}
          current={current}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          isValidSector={isValidSector}
          dataDetails={dataDetails}
          setAllSectors={setAllSectors}
          allSectors={allSectors}
        />
      )}
      {current === 3 && (
        <RegistroExepcionesEditar
          form={form}
          selectedSpecie={selectedSpecie}
          selectedSector={selectedSector}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          dataDetails={dataDetails}
        />
      )}
    </>
  );
};

export default StepperEditar;
