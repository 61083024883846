import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Card, Col, Row, Input, Divider, Badge, Skeleton } from "antd";
import moment from "moment";
import React from "react";
import { useParams } from "react-router-dom";

function DatosCuarentena({ datos, loading }) {
  const params = useParams();

  console.log("DATOS > ", datos);
  const formatDate = (dateTimestamp) => {
    let date = new Date();
    date?.setTime(dateTimestamp);
    return moment(date)?.format("YYYY-MM-DD - HH:mm:ss");
  };
  const formatEndDate = (dateTimestamp) => {
    let date = new Date();
    date?.setTime(dateTimestamp);
    return moment(date)?.format("YYYY-MM-DD");
  };

  const status = {
    1: "success",
    2: "error",
    3: "default",
    9: "default",
    11: "error",
  };

  const statusName = {
    1: "Vigente",
    2: "...",
    3: "Pendiente",
    9: "Finalizada",
    11: "Invalidada",
  };

  if (loading) {
    return (
      <div
        style={{
          minHeight: "600px",
          backgroundColor: "#fff",
          padding: "16px 8px",
          borderRadius: "6px",
        }}
      >
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 15,
          }}
        />
      </div>
    );
  }

  return (
    <Card
      style={{
        width: "90%",
        borderRadius: "8px",
      }}
    >
      <Row gutter={24}>
        <Col xs={24}>
          <div className="datos-cuarentena-caja">
            Creador:
            <p>
              {datos?.createdBy?.person?.name}{" "}
              {datos?.createdBy?.person?.lastname}
            </p>
            Inicio:
            <p>{formatDate(datos?.start_date)}</p>
            Término:
            <p>{formatEndDate(datos?.end_date)}</p>
            Estado:
            <p>
              <Badge
                text={statusName[datos?.status_id]}
                status={status[parseInt(datos?.status_id)]}
              />
            </p>
            {datos?.typeQuarantine?.name && (
              <>
                Motivo:
                <p>{datos?.typeQuarantine?.name}</p>
              </>
            )}
            Tipos de acceso:
            <br />
            <div>
              {datos?.typePersonalsAccessQuarantines?.map((personal) => {
                return (
                  <>
                    <Divider />
                    <ul>
                      <strong>Colaborador:</strong>
                      <li>{personal?.typePersonal?.name_type_personal}</li>
                      <strong>Permite acceso:</strong>
                      <li>{personal?.allow_access === "true" ? "Sí" : "No"}</li>
                      <strong>Noche de vacío:</strong>
                      <li> {personal?.number_empty_night}</li>
                    </ul>
                  </>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
}

export default DatosCuarentena;
