import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const useCuarentena = () => {
  const [isDataFetching, setIsDataFetching] = useState(true);
  const [dataDetails, setDataDetails] = useState(null);
  const [error, setError] = useState(null);

  const getDetails = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/${id}`
      );
      console.log("datacuarentena21", response?.data);

      const data = response.data ?? {};
      setDataDetails(data);
      setIsDataFetching(false);
    } catch (error) {
      console.error(error);
      setError(error);
      setIsDataFetching(false);
    }

    // console.log("datacuarentena22", dataDetails);
  };



  console.log("datacuarentena22", dataDetails);

  return { isDataFetching, dataDetails, error, getDetails };
};
