import {
  Row,
  Form,
  Input,
  Col,
  Select,
  Radio,
  message,
  Space,
  Checkbox,
} from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { EditOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useCountryCodes } from "utils/PhonePrefixes";
import useCountries from "utils/useCountries";
import useCargos from "utils/useCargos";
import { capitalize } from "utils/Capitalize";
// import { cargoColaborador } from "utils/Cargos";
import { useRut } from "utils/FormatRut";
import useMotivos from "utils/useMotivos";
// import { SelectEnterprice } from "./SelectEnterprice";

const FormDetalleVisita = ({ detallePerson }) => {
  const [form] = Form.useForm();
  const person = detallePerson ? detallePerson?.user?.person : {};
  const usuario = detallePerson ? detallePerson?.user : {};
  const typePersonal = detallePerson ? detallePerson?.user?.typePersonal : {};
  const [editInput, seteditInput] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState();
  const [resp, setResp] = useState();
  const [work, setWork] = useState();
  const [size, setSize] = useState();
  const [agreeCheck, setAgreeCheck] = useState(false);
  const { TextArea } = Input;

  const { countries } = useCountries();
  const { cargoColaborador } = useCargos();
  const { reason } = useMotivos();

  const { setWithLabel } = useCountryCodes();
  const { clean, format } = useRut();

  const [messageApi, contextHolder] = message.useMessage();

  const validateInput = (rule, value) => {
    const regex = /^\d+$/;
    if (value && !value.match(regex)) {
      return Promise.reject("Solo se permiten números");
    }
    return Promise.resolve();
  };

  const rulesPhone = [
    { required: false, message: "Campo requerido" },
    { validator: validateInput },
    {
      max: form.getFieldValue("codigoArea") === "+56" ? 9 : 20,
      message: "El número de teléfono no puede tener más de 9 dígitos",
    },
  ];

  const onChangeResp = (e) => {
    setResp(e.target.value);
  };

  const onChangeWork = (e) => {
    setWork(e.target.value);
  };
  const onChangeSize = (e) => {
    setSize(e.target.value);
  };

  const onChangeCheck = (e) => {
    setAgreeCheck(e.target.checked);
  };

  const handleEdit = () => {
    seteditInput(!editInput);
    if (editInput === true) {
      form.setFieldsValue({
        correo: usuario?.email || null,
        telefono: person?.phone_number || null,
      });
    }
  };

  useEffect(() => {
    setWithLabel(false);

    setAgreeCheck(true);
    form.setFieldsValue({
      responsible: detallePerson?.responsible,
      reason: detallePerson?.reason_id,
      anotherReason: detallePerson?.anotherReason,
      country: detallePerson?.country_id,
      city: detallePerson?.city,
      work_with_animals: detallePerson?.work_with_animals,
      shoe_size: detallePerson?.shoe_size,
      cloth_size: detallePerson?.cloth_size,
      agreeDeclaration: true,
    });
    // setRutSocialReason(enterPrice?.rut + verifyCodeCompany);
  }, [detallePerson]);

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: "flex",
          columnGap: "5px",
          marginTop: "5px",
          margin: "0 auto",
        }}
      >
        <Form
          form={form}
          layout="vertical"
          className="form_detalle"
          //   onFinish={handleSubmit}
          style={{
            width: "100%",
            padding: "0 40px",
          }}
        >
          {/* <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: "20px",
            }}
          >
            <SecondaryButton
              onButtonClick={handleEdit}
              width={150}
              icon={<EditOutlined />}
            >
              Editar Visita
            </SecondaryButton>
          </div> */}
          <Row
            className="row_form"
            style={{
              paddingLeft: "7px",
            }}
          >
            <Col span={12}>
              <div className="selector-fecha">
                <Form.Item
                  name="responsible"
                  label="Nombre del responsable en Agrosuper"
                >
                  <Input disabled={!editInput} />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="selector-fecha">
                <Form.Item name="reason" label="Motivo de la visita">
                  <Select
                    showSearch
                    placeholder="Seleccionar"
                    optionFilterProp="children"
                    options={reason?.map((item) => ({
                      value: item.id,
                      label: item.description,
                    }))}
                    onChange={(value) => setSelectedReason(value)}
                    disabled={!editInput}
                  />
                </Form.Item>
              </div>
            </Col>
            {selectedReason === 25 && (
              <Col span={24}>
                <div className="selector-fecha">
                  <Form.Item
                    name="anotherReason"
                    label="Declaración motivo de visita"
                  >
                    <TextArea
                      //   value={value}
                      //   onChange={(e) => setValue(e.target.value)}
                      placeholder="Controlled autosize"
                      autoSize={{
                        minRows: 3,
                        maxRows: 5,
                      }}
                      disabled={!editInput}
                    />
                  </Form.Item>
                </div>
              </Col>
            )}
          </Row>
          <Row
            className="row_form"
            style={{
              paddingLeft: "7px",
            }}
          >
            <Col span={12}>
              <Form.Item name="country" label="País">
                <Select
                  showSearch
                  placeholder="Seleccionar"
                  style={{
                    borderRadius: "6px",
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={
                    countries &&
                    countries.map((item) => ({
                      value: item.id,
                      label: item.name,
                      key: item.code,
                    }))
                  }
                  disabled={!editInput}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="city" label="Ciudad">
                <Input
                  style={{
                    borderRadius: "6px",
                  }}
                  size="small"
                  disabled={!editInput}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            className="row_form"
            style={{
              paddingLeft: "7px",
            }}
          >
            <Col span={8}>
              <Form.Item name="work_with_animals" label="Trabaja con animales">
                <Radio.Group
                  onChange={onChangeResp}
                  value={resp}
                  disabled={!editInput}
                >
                  <Radio value={true}>SI</Radio>
                  <Radio value={false}>NO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="shoe_size" label="Talla de calzado">
                <Radio.Group
                  onChange={onChangeWork}
                  value={work}
                  disabled={!editInput}
                >
                  <Space direction="vertical">
                    <Radio value={"37"}>37 (4.5)</Radio>
                    <Radio value={"38"}>38 (5.5)</Radio>
                    <Radio value={"39"}>39 (6)</Radio>
                    <Radio value={"40"}>40 (7)</Radio>
                    <Radio value={"41"}>41 (7.5)</Radio>
                    <Radio value={"42"}>42 (8)</Radio>
                    <Radio value={"43"}>43 (9)</Radio>
                    <Radio value={"44"}>44 (10)</Radio>
                    <Radio value={"45"}>45 (11)</Radio>
                    <Radio value={"46"}>46 (12)</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="cloth_size" label="Talla de vestuario">
                <Radio.Group
                  onChange={onChangeSize}
                  value={size}
                  disabled={!editInput}
                >
                  <Space direction="vertical">
                    <Radio value={"S"}>S</Radio>
                    <Radio value={"M"}>M</Radio>
                    <Radio value={"L"}>L</Radio>
                    <Radio value={"XL"}>XL</Radio>
                    <Radio value={"XXL"}>XXL</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row
            className="row_form"
            style={{
              paddingLeft: "7px",
            }}
          >
            <Col span={12}>
              <Form.Item
                name="agreeDeclaration"
                label="Declaración Visitas Nacionales e Internacionales"
              >
                <Checkbox
                  onChange={onChangeCheck}
                  checked={agreeCheck}
                  disabled={!editInput}
                >
                  Declara que ha leído y entendido en su totalidad las
                  condiciones de la visita
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {/* <div
            style={{
              display: editInput ? "flex" : "none",
              columnGap: "5px",
              marginTop: "5px",
              justifyContent: "center",
            }}
          >
            <SecondaryButton padding={8} onButtonClick={handleEdit}>
              Cancelar
            </SecondaryButton>
            <PrimaryButton
              isLoading={isLoading}
              type="submit"
              padding={8}
              width={160}
            >
              Guardar Cambios
            </PrimaryButton>
          </div> */}
        </Form>
      </div>
    </>
  );
};

export default FormDetalleVisita;
