import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Row,
  Card,
  Divider,
  Col,
  Input,
  message,
  Statistic,
  Badge,
  Dropdown,
  Space,
  Table,
  Form,
  Menu,
} from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { Link, useNavigate } from "react-router-dom";
import CustomSearch from "components/common/Forms/CustomSearch";
import {
  EllipsisOutlined,
  PlusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useRut } from "utils/FormatRut";
import { capitalize } from "utils/Capitalize";
import Highlighter from "react-highlight-words";
import { Button } from "antd/lib";

export default function RegistroExepciones({
  form,
  selectedSpecie,
  selectedSector,
  selectedRows,
  setSelectedRows,
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [users, setusers] = useState([]);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const sectorData = selectedSector.split("-");
  const [fetchConfig, setfetchConfig] = useState({
    limit: 2000,
    offset: 1,
  });
  const sectorId = sectorData[2];

  const { format } = useRut("");

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };

  console.log(selectedSector);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Seleccionar impar",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Seleccionar par",
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm, dataIndex) => {
    clearFilters();
    confirm({
      closeDropdown: false,
    });

    setSearchText("");
    setSearchedColumn("");
  };

  const plainOptions = useMemo(() => {
    return users
      .sort(
        (a, b) =>
          selectedRowKeys.includes(b.id) - selectedRowKeys.includes(a.id)
      )
      .map(({ id, enterprice, person, typePersonal, checked }) => {
        return {
          key: id,
          id,
          nombre: person,
          rut: person,
          rutEmpresa: enterprice,
          nombreEmpresa: enterprice,
          typePersonal,
        };
      });
  }, [selectedRowKeys, users]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm({
                closeDropdown: true,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, confirm, dataIndex)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Limpiar
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button> */}
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      switch (dataIndex) {
        case "nombre":
          return (
            record[dataIndex].name
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            record[dataIndex].lastname
              .toLowerCase()
              .includes(value.toLowerCase())
          );
        case "nombreEmpresa":
          return record[dataIndex][0].social_reason
            .toLowerCase()
            .includes(value.toLowerCase());
        case "rut":
          return (
            record[dataIndex].rut?.match(value.toLowerCase()) ||
            record[dataIndex].number_passport?.match(value.toLowerCase())
          );
        case "rutEmpresa":
          return record[dataIndex].rut?.match(value.toLowerCase());
        case "typePersonal":
          return record[dataIndex].name_type_personal
            .toLowerCase()
            .includes(value.toLowerCase());
        default:
          return "-";
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      const rutHasVerifyCode =
        text.has_code_rut_verify === "true"
          ? format(text.rut + "K")
          : format(text.rut);

      if (searchedColumn === dataIndex) {
        switch (searchedColumn) {
          case "nombre":
            return (
              <Highlighter
                highlightStyle={{
                  backgroundColor: "#ffc069",
                  padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={
                  searchedColumn === "nombre"
                    ? capitalize(text?.name) + " " + capitalize(text?.lastname)
                    : rutHasVerifyCode ?? text.number_passport
                }
              />
            );
          case "nombreEmpresa":
            return (
              <Highlighter
                highlightStyle={{
                  backgroundColor: "#ffc069",
                  padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={
                  text.length ? capitalize(text[0].social_reason) : ""
                }
              />
            );
          case "rut":
            return (
              <Highlighter
                highlightStyle={{
                  backgroundColor: "#ffc069",
                  padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={rutHasVerifyCode ?? text.number_passport}
              />
            );
          case "rutEmpresa":
            return (
              <Highlighter
                highlightStyle={{
                  backgroundColor: "#ffc069",
                  padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={rutHasVerifyCode ?? text.number_passport}
              />
            );
          case "typePersonal":
            return (
              <Highlighter
                highlightStyle={{
                  backgroundColor: "#ffc069",
                  padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={
                  text ? capitalize(text.name_type_personal) : ""
                }
              />
            );
          default:
            return "-";
        }
      } else {
        switch (dataIndex) {
          case "nombre":
            return text
              ? capitalize(text?.name) + " " + capitalize(text?.lastname)
              : "";
          case "nombreEmpresa":
            return text.length ? capitalize(text[0].social_reason) : "";
          case "rut":
            return rutHasVerifyCode ?? text.number_passport;
          case "rutEmpresa":
            return rutHasVerifyCode;
          case "typePersonal":
            return text ? capitalize(text.name_type_personal) : "";
          default:
            return "-";
        }
      }
    },
  });
  //
  const getData = useCallback(() => {
    if (selectedSpecie.code !== "004") {
      setIsDataFetching(true);
      const UsersSector = `${
        process.env.REACT_APP_BASE_URL_QUARANTINE
      }/quarantine/data-master/find-users-by-sector?limit=${2000}&offset=${Number(
        fetchConfig.offset
      )}&order=asc&code_specie=${selectedSpecie.code}&sectorId=${sectorId}`;
      const usrSec = axios.get(UsersSector);
      usrSec
        .then((response) => {
          setusers(response.data.users);
          setTotal(response.data.count);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setIsDataFetching(false);
        });
    } else {
      setIsDataFetching(true);
      const UsersPlants = `${
        process.env.REACT_APP_BASE_URL_QUARANTINE
      }/quarantine/data-master/find-users-by-food-plant?limit=${2000}&offset=${Number(
        fetchConfig.offset
      )}&order=asc&foodPlantId=${sectorData[0]}`;
      const usrPlant = axios.get(UsersPlants);
      usrPlant
        .then((response) => {
          setusers(response.data.users);
          setTotal(response.data.count);
        })
        .catch((error) => console.log(error))
        .finally(() => {
          setIsDataFetching(false);
        });
    }
  }, [selectedSector, selectedSpecie, fetchConfig]);

  const handlePaginationChange = ({ current, pageSize }, _) => {
    setfetchConfig({
      ...fetchConfig,
      limit: pageSize,
      offset: current,
    });
  };

  const columns = [
    {
      title: "Rut",
      dataIndex: "rut",
      ...getColumnSearchProps("rut"),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      ...getColumnSearchProps("nombre"),
    },
    {
      title: "Rut empresa",
      dataIndex: "rutEmpresa",
      ...getColumnSearchProps("rutEmpresa"),
    },
    {
      title: "R Social",
      dataIndex: "nombreEmpresa",
      ...getColumnSearchProps("nombreEmpresa"),
    },
    {
      title: "Tipo de colaborador",
      dataIndex: "typePersonal",
      ...getColumnSearchProps("typePersonal"),
    },
  ];

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      {contextHolder}

      <div className="crear-declaracion__container">
        <Col md={18}>
          <Card className="crear-declaracion__contenedor">
            <div className="filter_container">
              <div className="crear-declaracion__title">
                Configuración de
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  colaboradores afectados
                </span>
                <p className="crear-declaracion__subtitle">
                  Selecciona los colaboradores con excepción de cuarentena
                </p>
              </div>
              {/* <div className="filter_input">
                <CustomSearch />
              </div> */}
            </div>

            <div className="tabla-cuarentenas-container">
              <Form component={false}>
                <Table
                  loading={isDataFetching}
                  rowSelection={rowSelection}
                  rowKey={(record) => record.id}
                  dataSource={plainOptions}
                  columns={columns}
                  onChange={handlePaginationChange}
                  pagination={{
                    showTotal: (_total, range) =>
                      `${range[0]}-${range[1]} de ${total} registros`,
                    defaultPageSize: fetchConfig.limit,
                    total: total,
                    responsive: true,
                    showSizeChanger: false,
                  }}
                />
              </Form>
            </div>
          </Card>
        </Col>
      </div>
    </>
  );
}
