import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Row,
  Card,
  Divider,
  Col,
  Radio,
  Input,
  Space,
  Select,
  DatePicker,
  Form,
  Spin,
  Button,
} from "antd";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import es_ES from "antd/lib/locale/es_ES";

export default function EditarCuarentena({
  form,
  selectedSpecie,
  dataDetails,
  setColaboradores,
  colaboradores,
  removeColaboradores,
  setRemoveColaboradores,
}) {
  const [isDataFetching, setIsDataFetching] = useState(true);
  const [nochesVacio, setNochesVacio] = useState(null);
  const [tipoCuarentena, setTipoCuarentena] = useState([]);
  const [tipoColaborador, setTipoColaborador] = useState([]);
  const [typePersonalSelect, settypePersonalSelect] = useState(null);

  const { RangePicker } = DatePicker;

  const generarIdUnico1 = () => {
    return Math.random().toString(30).substring(2);
  };

  const handleAgregarColaborador = () => {
    setColaboradores([...colaboradores, { id: generarIdUnico1() }]);
  };

  const handleEliminarColaborador = (id) => {
    if (colaboradores.length > 1) {
      const nuevosColaboradores = colaboradores.filter(
        (item) => item.id !== id
      );
      const usuarioEliminado = colaboradores.filter((item) => item.id === id);
      setRemoveColaboradores([...removeColaboradores, ...usuarioEliminado]);
      setColaboradores(nuevosColaboradores);
    }
  };
  const setInfo = async () => {
    //
    const nocheVacioURL = `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/data-master/empty-nights`;
    const tipoColaboradorURL = `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/data-master/type-personal`;
    const arrayGet = [];

    const noc = axios.get(nocheVacioURL);

    const col = axios.get(tipoColaboradorURL);

    if (selectedSpecie.code !== "004") {
      const tipoCuarentenaURL = `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/data-master/type-quarantine?code_specie=${selectedSpecie.code}`;

      const cua = axios.get(tipoCuarentenaURL);

      arrayGet.push(noc, cua, col);
    } else {
      arrayGet.push(noc, col);
    }

    await axios
      .all(arrayGet)
      .then(
        axios.spread((...responses) => {
          if (selectedSpecie.code !== "004") {
            setNochesVacio(responses[0].data);
            setTipoCuarentena(responses[1].data);
            setTipoColaborador(responses[2].data);
          } else {
            setNochesVacio(responses[0].data);
            setTipoColaborador(responses[1].data);
          }
        })
      )
      .catch((error) => console.log(error))
      .finally(() => setIsDataFetching(false));
  };

  useEffect(() => {
    setInfo();
  }, []);

  useEffect(() => {
    if (dataDetails !== null) {
      const { typePersonalsAccessQuarantines } = dataDetails?.quarantines;
      setColaboradores(typePersonalsAccessQuarantines);
      settypePersonalSelect(typePersonalsAccessQuarantines.type_personal_id);
      let preData;
      typePersonalsAccessQuarantines.map((item, index) => {
        preData = {
          ...preData,
          [`tipoColaborador${item.id}`]: item.type_personal_id,
          [`nochesVacio${item.id}`]: item.number_empty_night,
          [`permiteAcceso${item.id}`]:
            item.allow_access === "true" ? true : false,
          [`correoElectronico${item.id}`]: item.is_notifiable,
        };
        return preData;
      });
      form.setFieldsValue({ ...preData });
    }
  }, [dataDetails, form]);


  const rules = [
    { required: selectedSpecie.code !== "004", message: "Campo requerido" },
  ];

  const permiteAcceso = [
    { label: "Si", value: true },
    { label: "No", value: false },
  ];

  const onSelect = (e) => {
    settypePersonalSelect(e);
  };

  const disabledDate = (current) => {
    return current && current < dayjs().endOf("day");
  };

  return (
    <>
      <div className="crear-cuarentena__container">
        <Col md={18}>
          <Card className="crear-cuarentena__contenedor">
            <div className="crear-cuarentena__title">
              Ingresar{" "}
              <span style={{ fontWeight: "600" }}>datos de la cuarentena</span>
            </div>
            <Divider />
            {isDataFetching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <>
                <Row gutter={40}>
                  {selectedSpecie.code !== "004" && (
                    <Col span={12}>
                      <Form.Item
                        name="tipoCuarentena"
                        label="Tipo de cuarentena"
                        rules={rules}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder="Seleccione..."
                          loading={isDataFetching}
                          disabled={isDataFetching}
                        >
                          {tipoCuarentena.map((data, i) => {
                            return (
                              <Select.Option value={data.id} key={i}>
                                {data.name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={12}>
                    <Form.Item
                      name="inicioTermino"
                      label="Fecha de inicio y término"
                      rules={rules}
                    >
                      <RangePicker
                        style={{ height: 36 }}
                        locale={es_ES}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={40}>
                  <Col span={24}>
                    <Form.Item
                      name="descripcionCuarentena"
                      label="Descripción"
                      rules={rules}
                    >
                      <Input.TextArea className="descripcionCuarentena" />
                    </Form.Item>
                  </Col>
                </Row>

                {colaboradores.map((colaborador, index) => (
                  <div className="colaboradorEntry" key={colaborador.id}>
                    <Divider />
                    <div className="cuarentena-grupo-head">
                      <div className="cuarentena-grupo-title">
                        GRUPO {index + 1}
                      </div>
                      <div className="cuarentena-grupo-delete">
                        {colaboradores.length > 1 && (
                          <Button
                            type="text"
                            className="btn-eliminarColaborador"
                            icon={<DeleteOutlined />}
                            onClick={() =>
                              handleEliminarColaborador(colaborador.id)
                            }
                          >
                            Eliminar
                          </Button>
                        )}
                        {/* {index === colaboradores.length - 1 &&
                          colaboradores.length > 1 && (
                            <Button
                              type="text"
                              className="btn-eliminarColaborador"
                              icon={<DeleteOutlined />}
                              onClick={() => handleEliminarColaborador(index)}
                            >
                              Eliminar
                            </Button>
                          )} */}
                      </div>
                    </div>
                    <Row gutter={40}>
                      <Col span={12}>
                        <Form.Item
                          name={`tipoColaborador${colaborador.id}`}
                          label="Tipo de colaborador"
                          rules={rules}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Seleccione..."
                            onSelect={onSelect}
                          >
                            {tipoColaborador.map((data, i) => (
                              <Select.Option value={data.id} key={i}>
                                {data.name_type_personal}
                              </Select.Option>
                            ))}
                            <Select.Option value="TDS">TODOS</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={`nochesVacio${colaborador.id}`}
                          label="Noches de Vacío"
                          rules={rules}
                        >
                          <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Seleccione..."
                          >
                            {nochesVacio.map((data, i) => (
                              <Select.Option
                                value={data.number_assigned}
                                key={i}
                              >
                                {data.number_assigned !== 0
                                  ? data.number_assigned + " Noches de Vacío"
                                  : "Sin restricción"}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={40}>
                      <Col span={12}>
                        <Form.Item
                          name={`permiteAcceso${colaborador.id}`}
                          label="Permite Acceso"
                          rules={rules}
                        >
                          <Select
                            showSearch
                            placeholder="Seleccionar"
                            optionFilterProp="children"
                            options={permiteAcceso}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={`correoElectronico${colaborador.id}`}
                          label="Correo electrónico (para notificar cuarentena)"
                          rules={rules}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ))}
                {typePersonalSelect !== "TDS" ? (
                  <Button
                    type="text"
                    className="btn-nuevoColaborador"
                    icon={<PlusCircleOutlined />}
                    onClick={handleAgregarColaborador}
                  >
                    Agregar nuevo tipo de colaborador
                  </Button>
                ) : null}
              </>
            )}
          </Card>
        </Col>
      </div>
    </>
  );
}
