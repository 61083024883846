import { Checkbox, Collapse, DatePicker, Drawer, Form } from "antd";
import { PrimaryButton } from "components/common/Buttons";
import { useCallback, useEffect, useState } from "react";
import useWindowSize from "utils/useWindowSize";
import "moment/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import axios from "axios";
import { useResetFormOnButtonClick } from "utils/useResetFormOnButtonClick";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const FiltrosTablaExcepciones = ({
  form,
  visible,
  onClose,
  setIsFiltering,
  payload,
  setPayload,
  fetchConfig,
  userId,
  setTotal,
  vigencyOption,
  setVigencyOption,
  vigencyStatus,
  setVigencyStatus,
}) => {
  const { windowWidth } = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    console.log("values", values);
    if (vigencyOption) {
      setPayload({
        ...payload,
        rangeDate: vigencyOption,
      });
    }
    if (vigencyStatus.length > 0) {
      setPayload({
        ...payload,
        status: vigencyStatus,
      });
    }

    setIsFiltering(true);
    onClose();
  };

  const estadosVigencia = [
    { label: "Activo", value: 1 },
    { label: "Invalidada", value: 11 },
    { label: "Pendiente", value: 3 },
    { label: "Finalizado", value: 9 },
  ];

  const handleChooseDates = (value, dateString) => {
    if (value === null) {
      //delete rangeDate from payload
      setVigencyOption({});
      return;
    }
    setVigencyOption({
      range_start: dateString[0],
      range_end: dateString[1],
    });
  };

  const handleVigencyStatus = (list) => {
    console.log("list", list);
    setVigencyStatus(list);

  };

  return (
    <div className="filtros-tabla-excepciones">
      <Drawer
        title="Filtros"
        placement="right"
        onClose={onClose}
        visible={visible}
        width={windowWidth > 768 ? 600 : "80%"}
      >
        <Form form={form} onFinish={onSubmit}>
          <div className="filter-criteria">
            <Collapse ghost expandIconPosition="end">
              <Panel header="Fecha de creación" key="1">
                <div className="filter-item">
                  <Form.Item name="dateCreate">
                    <RangePicker
                      style={{ borderRadius: "6px" }}
                      locale={locale}
                      onChange={handleChooseDates}
                      format="MM/DD/YYYY"
                    />
                  </Form.Item>
                </div>
              </Panel>
              <Panel header="Estado vigencia" key="3">
                <div className="filter-item">
                  <Form.Item name="status">
                    <Checkbox.Group
                      value={vigencyStatus}
                      onChange={handleVigencyStatus}
                    >
                      {estadosVigencia.map((estado) => {
                        return (
                          <Checkbox
                            key={estado.value}
                            value={estado.value}
                            style={{ marginBottom: "10px", marginLeft: "0" }}
                          >
                            {estado.label}
                          </Checkbox>
                        );
                      })}
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              </Panel>
            </Collapse>
          </div>

          <div
            className="filter_btn"
            style={{ textAlign: "center", marginTop: "50px" }}
          >
            <PrimaryButton
              width={windowWidth < 425 ? 200 : 250}
              type="submit"
              isLoading={isLoading}
              onButtonClick={onSubmit}
            >
              Aplicar filtros
            </PrimaryButton>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default FiltrosTablaExcepciones;
