import React, { useEffect, useState } from "react";
import { Row, Card, Divider, Col, Checkbox, Select, Form } from "antd";
import axios from "axios";
import { capitalize } from "lodash";
import PermissionsAccess from "./PermissionsAccess";

export default function SectoresAfectados({
  form,
  selectedSpecie,
  selectedSector,
  current,
  isValidSector,
  setAllSectors,
  allSectors,
}) {
  const sectorData = selectedSector.split("-");
  let sectorName;

  if (selectedSpecie.code === "004") {
    sectorName = sectorData[1];
  } else {
    sectorName = sectorData[3];
  }

  const [selectData, setSelectData] = useState([]);

  const onChange = (e) => {
    setAllSectors(e.target.checked);
  };

  return (
    <>
      <div className="crear-declaracion__container">
        <Col md={18}>
          <Card className="crear-declaracion__contenedor">
            <div className="crear-declaracion__title">
              Seleccionar{" "}
              <span style={{ fontWeight: "600" }}>
                {selectedSpecie.code !== "004" ? "niveles" : "plantas"} a
                restringir
              </span>
            </div>
            <Divider />
            <Row>
              <div className="detalle_cuarentena_container">
                <div className="btn-container">
                  <div className="type_selector">
                    <div
                      className="selector active"
                      style={{
                        width:
                          selectedSpecie.code !== "004" ? "100px" : "150px",
                      }}
                    >
                      {selectedSpecie.name_specie}
                    </div>
                  </div>
                </div>
                <div className="cuarentena-selected-sector">
                  <p>
                    {" "}
                    {selectedSpecie.code !== "004" ? "Sector" : "Instalación"}
                  </p>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={sectorName}
                    disabled={true}
                  >
                    <Select.Option value={sectorName} key={1}>
                      {sectorName}
                    </Select.Option>
                  </Select>
                </div>
                <br />
                {selectedSpecie.code !== "004" && (
                  <div>
                    <Checkbox checked={allSectors} onChange={onChange}>
                      {" "}
                      Acceso total
                    </Checkbox>
                  </div>
                )}
                <Col xs={24} md={24}>
                  <PermissionsAccess
                    form={form}
                    code={selectedSpecie.code}
                    idSpecie={selectedSpecie.id}
                    current={current}
                    isValidSector={isValidSector}
                    plantaSelected={sectorData[0]}
                    allSectors={allSectors}
                  />
                </Col>
              </div>
            </Row>
          </Card>
        </Col>
      </div>
    </>
  );
}
