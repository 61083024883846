import React, { useEffect, useState } from "react";
import { Row, Card, Divider, Col, Checkbox, Select, Form } from "antd";
import axios from "axios";
import { capitalize } from "lodash";
import PermissionsAccess from "./PermissionsAccessEditar";
import PermissionsAccessEditar from "./PermissionsAccessEditar";
import { IdTokenEntity } from "@azure/msal-common";

export default function SectoresAfectadosEditar({
  form,
  selectedSpecie,
  selectedSector,
  current,
  isValidSector,
  dataDetails,
  setAllSectors,
  allSectors,
}) {
  const sectorData = selectedSector.split("-");
  let sectorName;

  if (selectedSpecie.code === "004") {
    sectorName = sectorData[1];
  } else {
    sectorName = sectorData[3];
  }

  const [selectData, setSelectData] = useState([]);


  useEffect(() => {
    if (dataDetails !== null) {
      const { sectors_until } = dataDetails;
      let preData = {};
      const level = sectors_until?.map((item) => item?.level_id);
      const dataArr = new Set(level);

      let result = [...dataArr];
      const filter = selectData.filter((ite) => result.includes(ite.key));
      filter.map((ite) => {
        const value = [];
        sectors_until?.map((item) => {
          if (item?.level_id === ite.key) {
            value.push(`${item?.level_id}-${item?.zone_id}-${item?.id}`);
          }
        });

        preData = {
          ...preData,
          [`sectorTreeData${ite.key}`]: value,
        };
      });
      const arrayzonessall = [];
      const arraysectorssall = [];
      selectData.map((data) => {
        arrayzonessall.push(...data.children);
      });
      const ids = sectors_until?.filter((item) => item !== null);

      arrayzonessall.map((zones) => arraysectorssall.push(...zones.children));
      setAllSectors(ids.length === arraysectorssall.length);
      form.setFieldsValue(preData);
    }
  }, [dataDetails, form, selectData]);

  const onChange = (e) => {
    setAllSectors(e.target.checked);
  };

  return (
    <>
      <div className="crear-declaracion__container">
        <Col md={18}>
          <Card className="crear-declaracion__contenedor">
            <div className="crear-declaracion__title">
              Seleccionar{" "}
              <span style={{ fontWeight: "600" }}>
                {selectedSpecie.code !== "004" ? "niveles" : "plantas"} a
                restringir
              </span>
            </div>
            <Divider />
            <Row>
              <div className="detalle_cuarentena_container">
                <div className="btn-container">
                  <div className="type_selector">
                    <div className="selector active">
                      {selectedSpecie.name_specie}
                    </div>
                  </div>
                </div>
                <div className="cuarentena-selected-sector">
                  <p>
                    {selectedSpecie.code !== "004" ? "Sector" : "Instalación"}
                  </p>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder={sectorName}
                    disabled={true}
                  >
                    <Select.Option value={sectorName} key={1}>
                      {sectorName}
                    </Select.Option>
                  </Select>
                </div>
                <br />
                {selectedSpecie.code !== "004" && (
                  <div>
                    <Checkbox checked={allSectors} onChange={onChange}>
                      {" "}
                      Acceso total
                    </Checkbox>
                  </div>
                )}

                <Col xs={24} md={24}>
                  <PermissionsAccessEditar
                    form={form}
                    code={selectedSpecie.code}
                    idSpecie={selectedSpecie.id}
                    current={current}
                    isValidSector={isValidSector}
                    plantaSelected={sectorData[0]}
                    dataDetails={dataDetails}
                    allSectors={allSectors}
                    setSelectData={setSelectData}
                  />
                </Col>
              </div>
            </Row>
          </Card>
        </Col>
      </div>
    </>
  );
}
