import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Col, Divider, Form, TreeSelect, Spin, Select } from "antd";
import { capitalize } from "utils/Capitalize";
import { AlertModal, ChangeStatusModal } from "components/common/modals/modals";
import { Navigate } from "react-router-dom";

const FormNuevaCuarentena = ({
  form,
  setSelectedSpecie,
  selectedSpecie,
  setDataForm,
  specie,
  isLoading,
  setSelectedDates,
  selectedDates,
  setSelectedSector,
  selectedSector,
  errorSpecieSelected,
  seterrorSpecieSelected,
}) => {
  const [specieType, setSpecieType] = useState({});
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [sectors, setSectors] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });

  const getData = async (type) => {
    setIsDataFetching(true);
    let sec = [];
    if (type.code !== "004") {
      const sectorURL =
        process.env.REACT_APP_BASE_URL_QUARANTINE +
        "/quarantine/data-master/findsector?code_specie=" +
        type.code;
      sec = axios.get(sectorURL);
    } else {
      const sectorURL =
        process.env.REACT_APP_BASE_URL_QUARANTINE +
        "/quarantine/data-master/find-by-food-plant";
      sec = axios.get(sectorURL);
    }

    await axios
      .all([sec])
      .then(
        axios.spread((...responses) => {
          setSectors(responses[0].data);
        })
      )
      .catch((error) => console.error(error));

    setIsDataFetching(false);
  };

  const nameButton = (name) => {
    const finalName = name.split(" ");
    if (name === "Cerdos" || name === "Pavos" || name === "Pollos") {
      return finalName[0];
    } else {
      return finalName[1];
    }
  };

  const handleType = async (type) => {
    if (selectedSpecie && selectedSpecie.code === type.code) {
      setSelectedSpecie("");
      setSpecieType({});
      setSectors([]);
      form.resetFields();
    } else {
      setSpecieType((prev) => (prev === type ? "" : type));
      setSelectedSpecie(type);
      seterrorSpecieSelected("");
      form.resetFields();
      getData(type);
    }
  };

  const rules = [{ required: true, message: "Campo requerido" }];

  const clickSector = (values, e) => {
    setSelectedSector(values);
  };

  const opctionSelect2 = (sector) => {
    let opt = [];

    sector?.map((sec) => {
      let afs = {
        label: `${capitalize(sec.name)}`,
        value: `${sec.id}-${sec.name}`,
        disabled: sec.status_id === 1 ? false : true,
      };
      opt.push(afs);
    });
    return opt;
  };

  const optionsSelect = (sector) => {
    let opt = [];

    sector?.map((sec) => {
      let afs = {
        label: `${capitalize(sec.name)}`,
        value: `${sec.level_id}-${sec.zone_id}-${sec.id}-${sec.name}`,
        disabled:
          sec.level.status_id === 2 ||
          sec.zone.status_id === 2 ||
          sec.status_id === 2
            ? true
            : false,
      };
      opt.push(afs);
    });
    return opt;
  };

  useEffect(() => {
    if (Object.keys(selectedSpecie).length > 0) {
      getData(selectedSpecie);
    }
  }, []);

  return (
    <>
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => Navigate("/registro-movimientos")}
      >
        {modalInfo.message}
      </AlertModal>
      <div className="crear-declaracion__container">
        <Col md={18}>
          <Card className="crear-declaracion__contenedor">
            <div className="crear-declaracion__title">
              <span style={{ fontWeight: "600" }}>datos de la cuarentena</span>
            </div>
            <Divider />
            <div className="detalle_cuarentena_container">
              <div className="btn-container">
                <div className="type_selector">
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spin size="large" />
                    </div>
                  ) : (
                    specie.length > 0 && (
                      <>
                        {specie.map((spec, i) => {
                          return (
                            <div
                              key={spec.id}
                              className={
                                specieType.code === spec.code ||
                                selectedSpecie.code === spec.code
                                  ? "selector active"
                                  : "selector"
                              }
                              onClick={() => handleType(spec)}
                            >
                              {nameButton(spec.name_specie)}
                            </div>
                          );
                        })}
                      </>
                    )
                  )}
                </div>
                <div>
                  {errorSpecieSelected !== "" ? (
                    <div
                      style={{
                        marginTop: "5px",
                        color: "red",
                      }}
                    >
                      {errorSpecieSelected}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="cuarentena_sector">
                {isDataFetching ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spin size="large" />
                  </div>
                ) : null}

                {sectors.length && !isDataFetching ? (
                  <div>
                    <Form.Item
                      name="selectorsector"
                      label="Seleccionar instalación"
                      validateTrigger={["onBlur"]}
                      rules={rules}
                    >
                      <Select
                        showSearch
                        options={
                          selectedSpecie.code === "004"
                            ? opctionSelect2(sectors)
                            : optionsSelect(sectors)
                        }
                        onSelect={clickSector}
                      />
                    </Form.Item>
                  </div>
                ) : null}
              </div>
            </div>
          </Card>
        </Col>
      </div>
    </>
  );
};

export default FormNuevaCuarentena;
