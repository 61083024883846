import { Col, Form, Radio, Row, Select, Spin, TreeSelect } from 'antd'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { capitalize } from 'utils/Capitalize 2'

const { Option } = Select
const { SHOW_PARENT } = TreeSelect

const Permisos = ({
  form,
  radioValue,
  setRadioValue,
  treeData,
  setTreeData,
  ...props
}) => {
  const [isDataFetching, setIsDataFetching] = useState(false)
  const [sectorsData, setSectorsData] = useState([])
  const [selectedLevels, setSelectedLevels] = useState([])
  const { date_start, date_end, specieId } = props
  const [levels, setLevels] = useState([])
  const [zones, setZones] = useState([])
  const [sectors, setSectors] = useState([])

  useEffect(() => {
    const getData = async () => {
      const nivelURL =
        process.env.REACT_APP_BASE_URL_REQUESTS +
        '/data-master/findlevel?code_specie=' +
        props.code
      const zonaURL =
        process.env.REACT_APP_BASE_URL_REQUESTS +
        '/data-master/findzone?code_specie=' +
        props.code
      const sectorURL =
        process.env.REACT_APP_BASE_URL_REQUESTS +
        '/data-master/findsector?code_specie=' +
        props.code
      const niv = axios.get(nivelURL)
      const zon = axios.get(zonaURL)
      const sec = axios.get(sectorURL)
      await axios
        .all([niv, zon, sec])
        .then(
          axios.spread((...responses) => {
            setLevels(responses[0].data)
            setZones(responses[1].data)
            setSectors(responses[2].data)
          })
        )
        .catch((error) => console.error(error))
    }
    getData()
  }, [])

  const onChange = (e) => {
    form.setFieldsValue({
      listLevel: null,
      listZone: null,
      sectores: undefined
    })
    setRadioValue(e.target.value)
    switch (e.target.value) {
      case 'level':
        handleLevels()
        setSelectedLevels([])
        // fetchLevels();
        break
      case 'zone':
        handleZones()
        setSelectedLevels([])
        // fetchZones();
        break
      case 'sector':
        setSelectedLevels([])
        setTreeData([])
        fetchSectors()
        break
      case 'all':
        setSelectedLevels([])
        setTreeData([])
        props.data(props.code, e.target.value)
        break

      default:
    }
  }

  const filterSectors = (sectorArr) => {
    const filteredSectors = sectorArr.filter(
      (sector) => sector.status_id !== 6 && sector.zone.specie_id === props.id
    )

    return filteredSectors
  }

  const addLevels = (values, e) => {
    const levelId = parseInt(e.value.split('-')[0])
    const zoneId = parseInt(e.value.split('-')[1])

    const isLevelExist = selectedLevels.find(
      (level) => level.level_id === parseInt(e.value.split('-')[0])
    )

    if (!isLevelExist) {
      if (values.split('-').length === 2) {
        const tempObj = {
          level_id: parseInt(e.value.split('-')[0]),
          date_start: date_start,
          date_end: date_end,
          zones: [
            {
              zone_id: parseInt(e.value.split('-')[1]),
              date_start: date_start,
              date_end: date_end
            }
          ]
        }
        if (e.children.length) {
          tempObj.zones[0].sectors = e.children.map((sector) => {
            return {
              sector_id: parseInt(sector.value.split('-')[2]),
              date_start: date_start,
              date_end: date_end
            }
          })
        }
        const selectedLevelsCopy = [...selectedLevels]
        selectedLevelsCopy.push(tempObj)
        props.data(props.code, selectedLevelsCopy)
        setSelectedLevels(selectedLevelsCopy)
      }
      if (values.split('-').length === 3) {
        const tempObj = {
          level_id: parseInt(e.value.split('-')[0]),
          date_start: date_start,
          date_end: date_end,
          zones: [
            {
              zone_id: parseInt(e.value.split('-')[1]),
              date_start: date_start,
              date_end: date_end,
              sectors: [
                {
                  sector_id: parseInt(e.value.split('-')[2]),
                  date_start: date_start,
                  date_end: date_end
                }
              ]
            }
          ]
        }
        const selectedLevelsCopy = [...selectedLevels]
        selectedLevelsCopy.push(tempObj)
        props.data(props.code, selectedLevelsCopy)
        setSelectedLevels(selectedLevelsCopy)
      }
    }

    if (isLevelExist) {
      const selectedLevelsCopy = [...selectedLevels]
      const levelIndex = selectedLevelsCopy.findIndex(
        (level) => level.level_id === levelId
      )

      if (values.split('-').length === 2) {
        const zoneIndex = selectedLevelsCopy[levelIndex].zones.findIndex(
          (zone) => zone.zone_id === zoneId
        )
        if (zoneIndex === -1) {
          selectedLevelsCopy[levelIndex].zones.push({
            zone_id: zoneId,
            date_start: date_start,
            date_end: date_end
          })
        } else {
          selectedLevelsCopy[levelIndex].zones.splice(zoneIndex, 1)
        }

        if (e.children.length) {
          selectedLevelsCopy[levelIndex].zones[0].sectors = e.children.map(
            (sector) => {
              return {
                sector_id: parseInt(sector.value.split('-')[2]),
                date_start: date_start,
                date_end: date_end
              }
            }
          )
        }
      }
      if (values.split('-').length === 3) {
        const zoneIndex = selectedLevelsCopy[levelIndex].zones.findIndex(
          (zone) => zone.zone_id === zoneId
        )

        if (zoneIndex === -1) {
          selectedLevelsCopy[levelIndex].zones.push({
            zone_id: zoneId,
            date_start: date_start,
            date_end: date_end,
            sectors: [
              {
                sector_id: parseInt(e.value.split('-')[2]),
                date_start: date_start,
                date_end: date_end
              }
            ]
          })
        } else {
          selectedLevelsCopy[levelIndex].zones.splice(zoneIndex, 1)
        }
      }
      props.data(props.code, selectedLevelsCopy)
      setSelectedLevels(selectedLevelsCopy)
    }
  }

  const handleLevels = () => {
    const specieSectors = filterSectors(sectors)
    const zonesByLevel = specieSectors.reduce((acc, sector) => {
      const { id: sectorId, name: sectorName } = sector
      const { id: zoneId, name: zoneName } = sector.zone
      const { id: levelId, name: levelName } = sector.level

      if (!acc[levelId]) {
        acc[levelId] = {
          title: levelName,
          key: levelId,
          value: `${levelId}`,
          children: []
        }
      }

      const levelNode = acc[levelId]
      let zoneNode = levelNode.children.find((node) => node.key === zoneId)

      if (!zoneNode) {
        zoneNode = {
          title: zoneName,
          key: zoneId,
          value: `${levelId}-${zoneId}`,
          children: []
        }
        levelNode.children.push(zoneNode)
      }

      zoneNode.children.push({
        title: sectorName,
        key: sectorId,
        value: `${levelId}-${zoneId}-${sectorId}`
      })

      return acc
    }, {})

    const treeData = Object.values(zonesByLevel)

    setTreeData(treeData)
  }

  const handleZones = () => {
    const specieSectors = filterSectors(sectors)

    const zonesBySector = specieSectors.reduce((acc, sector) => {
      const { id: sectorId, name: sectorName } = sector
      const { id: zoneId, name: zoneName } = sector.zone
      const { id: levelId, name: levelName } = sector.level

      if (!acc[zoneId]) {
        acc[zoneId] = {
          title: zoneName,
          key: zoneId,
          value: `${zoneId}`,
          children: []
        }
      }

      const zoneNode = acc[zoneId]
      let levelNode = zoneNode.children.find((node) => node.key === levelId)

      if (!levelNode) {
        levelNode = {
          title: levelName,
          key: levelId,
          value: `${zoneId}-${levelId}`,
          children: []
        }
        zoneNode.children.push(levelNode)
      }

      levelNode.children.push({
        title: sectorName,
        key: sectorId,
        value: `${zoneId}-${levelId}-${sectorId}`
      })

      return acc
    }, {})

    const treeData = Object.values(zonesBySector)

    setTreeData(treeData)
  }

  const fetchSectors = async () => {
    setIsDataFetching(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findsector?code_specie=${props.code}`
      )
      const activeSectors = response.data.filter(
        (sector) => parseInt(sector.status_id) !== '6'
      )

      const tempData = activeSectors.map((sector, index) => {
        return {
          title: `${capitalize(sector.level.name)} - ${capitalize(
            sector.zone.name
          )} - ${capitalize(sector.name)}`,
          value: `${sector.level.id}-${sector.zone.id}-${sector.id}`,
          key: `${sector.level.id}-${sector.zone.id}-${sector.id}`
        }
      })
      setSectors(activeSectors)
      setSectorsData(tempData)
      setIsDataFetching(false)
    } catch (error) {
      console.error(error)
      setIsDataFetching(false)
    }
  }

  const addSectors = (values) => {
    const [levelId, zoneId, sectorId] = values
      .split('-')
      .map((value) => parseInt(value, 10))

    const isLevelExist = selectedLevels.find(
      (level) => level.level_id === parseInt(levelId)
    )

    if (!isLevelExist) {
      const tempObj = {
        level_id: levelId,
        date_start: date_start,
        date_end: date_end,
        zones: [
          {
            zone_id: zoneId,
            date_start: date_start,
            date_end: date_end,
            sectors: [
              {
                sector_id: sectorId,
                date_start: date_start,
                date_end: date_end
              }
            ]
          }
        ]
      }
      const selectedLevelsCopy = [...selectedLevels]
      selectedLevelsCopy.push(tempObj)
      props.data(props.code, selectedLevelsCopy)
      setSelectedLevels(selectedLevelsCopy)
    }

    if (isLevelExist) {
      const selectedLevelsCopy = [...selectedLevels]
      const levelIndex = selectedLevelsCopy.findIndex(
        (level) => level.level_id === levelId
      )

      const zoneIndex = selectedLevelsCopy[levelIndex].zones.findIndex(
        (zone) => zone.zone_id === zoneId
      )

      if (zoneIndex === -1) {
        selectedLevelsCopy[levelIndex].zones.push({
          zone_id: zoneId,
          date_start: date_start,
          date_end: date_end,
          sectors: [
            {
              sector_id: sectorId,
              date_start: date_start,
              date_end: date_end
            }
          ]
        })
      } else {
        selectedLevelsCopy[levelIndex].zones[zoneIndex].sectors.push({
          sector_id: sectorId,
          date_start: date_start,
          date_end: date_end
        })
      }
      props.data(props.code, selectedLevelsCopy)
      setSelectedLevels(selectedLevelsCopy)
    }
  }

  const rules = [{ required: true, message: 'Seleccione una opción' }]

  return (
    <div className='formulario-permisos container'>
      <Row style={{ margin: '0.75rem 0' }}>
        <Col span={24}>
          <h4 className='formulario-permisos-title'>
            Selecciona el área para comenzar
          </h4>
        </Col>
      </Row>
      <Form form={form} requiredMark={false}>
        <Row>
          <Col span={24} style={{ margin: '8px 0 16px' }}>
            <Form.Item
              name='radio-buttons'
              rules={[
                () => ({
                  validator(rule, value) {
                    if (radioValue !== '') {
                      return Promise.resolve()
                    }
                    return Promise.reject(`Seleccione una opción`)
                  }
                })
              ]}
            >
              <div className='radio-buttons'>
                <Radio.Group
                  onChange={onChange}
                  value={radioValue}
                  disabled={isDataFetching}
                >
                  <Radio value='level'>Nivel</Radio>
                  <Radio value='zone'>Zona</Radio>
                  <Radio value='sector'>Sector</Radio>
                  <Radio value='all'>Acceso Total</Radio>
                </Radio.Group>
              </div>
            </Form.Item>
          </Col>
        </Row>

        {isDataFetching ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Spin size='large' />
          </div>
        ) : null}

        <Row gutter={[32, 16]}>
          {() => {
            switch (radioValue) {
              case 'level':
                handleLevels()
                setSelectedLevels([])
                // fetchLevels();
                break
              case 'zone':
                handleZones()
                setSelectedLevels([])
                // fetchZones();
                break
              case 'sector':
                setSelectedLevels([])
                setTreeData([])
                fetchSectors()
                break
              case 'all':
                setSelectedLevels([])
                setTreeData([])
                props.data(props.code, radioValue)
                break

              default:
            }
          }}
          <Col span={24}>
            {treeData.length > 0 && radioValue === 'level' ? (
              <Form.Item
                name='list-data'
                className='list-data-form'
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const list = getFieldValue('listLevel') ?? {}

                      if (Object.keys(list).length > 0) {
                        return Promise.resolve()
                      }
                      return Promise.reject(`Seleccione un Nivel`)
                    }
                  })
                ]}
              >
                <Form.List name='listLevel'>
                  {() => (
                    <>
                      {treeData.map((item) => (
                        <Col span={12} key={item.value}>
                          <p>{capitalize(item.title)}</p>
                          <Form.Item name={`${item.title}-${item.key}`}>
                            <TreeSelect
                              showArrow
                              showCheckedStrategy={SHOW_PARENT}
                              treeNodeFilterProp='title'
                              treeData={item.children}
                              onSelect={addLevels}
                              treeCheckable={true}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                      ))}
                    </>
                  )}
                </Form.List>
              </Form.Item>
            ) : null}
            {treeData.length > 0 && radioValue === 'zone' ? (
              <Form.Item
                name='list-data'
                className='list-data-form'
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      const list = getFieldValue('listZone') ?? {}
                      if (Object.keys(list).length > 0) {
                        return Promise.resolve()
                      }
                      return Promise.reject(`Seleccione una Zona`)
                    }
                  })
                ]}
              >
                <Form.List name='listZone'>
                  {() => (
                    <>
                      {treeData.map((item) => (
                        <Col span={12} key={item.value}>
                          <p>{capitalize(item.title)}</p>
                          <Form.Item name={`${item.title}-${item.key}`}>
                            <TreeSelect
                              showArrow
                              showCheckedStrategy={SHOW_PARENT}
                              treeNodeFilterProp='title'
                              treeData={item.children}
                              onSelect={addLevels}
                              treeCheckable={true}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                      ))}
                    </>
                  )}
                </Form.List>
              </Form.Item>
            ) : null}
            {sectorsData.length && radioValue === 'sector' ? (
              <Col xs={24} md={12}>
                <p>Sectores</p>
                <Form.Item name='sectores' rules={rules}>
                  <TreeSelect
                    allowClear
                    showArrow
                    treeCheckable={true}
                    treeNodeFilterProp='title'
                    treeData={sectorsData}
                    onSelect={addSectors}
                  />
                </Form.Item>
              </Col>
            ) : null}
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default Permisos
