import React, { useCallback, useEffect, useState } from "react";
import {
  useSearchParams,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Steps, Col, Form, Row, Button } from "antd";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import axios from "axios";
import Stepper from "./StepperEditar";
import moment from "moment";
import { AlertModal } from "components/common/modals/modals";
import { LoadingOutlined } from "@ant-design/icons";
import StepperEditar from "./StepperEditar";
import { useCuarentena } from "utils/useCuarentena";

const ExistenteCuarentena = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(0);
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [selectedSpecie, setSelectedSpecie] = useState("");
  const [selectedSector, setSelectedSector] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [specie, setSpecie] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState("");
  const [dataForm, setDataForm] = useState([]);
  const [errorSpecieSelected, seterrorSpecieSelected] = useState("");
  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({});
  const [currentValues, setCurrentValues] = useState({});
  const [isValidSector, setIsValidSector] = useState(false);
  const tipoVisita = parseInt(searchParams.get("tipoVisita")) || 1;
  const [allSectors, setAllSectors] = useState(false);
  const [colaboradores, setColaboradores] = useState([{ id: 1 }]);
  const [removeColaboradores, setRemoveColaboradores] = useState([]);

  const formData = form?.getFieldsValue(true);
  const sectorData = selectedSector.split("-");

  const [showModal, setShowModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    open: false,
    type: "",
    title: "",
    message: "",
  });

  const { dataDetails, getDetails } = useCuarentena();


  useEffect(() => {
    getDetails(params.id);
    getSpecies();
  }, []);

  const processSectors = async (sectorTreeData) => {
    const sectors = [];

    for (const sectorData of sectorTreeData) {
      const numbers = sectorData.split("-");


      if (numbers.length === 3) {
        sectors.push({
          zone_id: Number(numbers[1]),
          level_id: Number(numbers[0]),
          sector_id: Number(numbers[2]),
        });
      } else if (numbers.length === 2) {
        sectors.push({
          zone_id: Number(numbers[1]),
          level_id: Number(numbers[0]),
        });
      }
    }

    return sectors;
  };


  const processFormData = async (formData) => {
    const sectorTreeDataKeys = Object.keys(formData).filter((key) =>
      key.startsWith("sectorTreeData")
    );


    const allProcessedSectors = await Promise.all(
      sectorTreeDataKeys.map((key) => processSectors(formData[key]))
    );

    const sectores = allProcessedSectors.flat();
    return {
      sector_untilXS: sectores,
    };
  };

  const SpinIcon = () => (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );


  const handleFinish = async (values) => {
    if (current < 3) {
      if (!selectedSpecie.code) {
        seterrorSpecieSelected("No se ha seleccionado ninguna especie");
        return;
      }
      setCurrent(current + 1);
    } else {
      setIsLoading(true);
      let payload = [];
      let type_personal = [];

      await processFormData(formData)
        .then((processedData) => {
          const sectorNumbers = processedData.sector_untilXS;
          formData.sector_until = sectorNumbers;
        })
        .catch((error) => {
          console.error("Error processing formData:", error);
        });

      const { typePersonalsAccessQuarantines, quarantineUserExceptions } =
        dataDetails.quarantines;

      const idsTypePersonalAccess = typePersonalsAccessQuarantines?.map(
        (item) => item.id
      );

      colaboradores.forEach((colaborador) => {
        if (!idsTypePersonalAccess.includes(colaborador.id)) {
          const tipoColaborador = formData[`tipoColaborador${colaborador.id}`];
          const nochesVacio = formData[`nochesVacio${colaborador.id}`];
          const permiteAcceso = formData[`permiteAcceso${colaborador.id}`];
          const correoElectronico =
            formData[`correoElectronico${colaborador.id}`];
          if (tipoColaborador === "TDS") {
            type_personal.push({
              allow_access: permiteAcceso,
              number_empty_night: nochesVacio,
              is_notifiable: correoElectronico,
              all: true,
            });
          } else {
            type_personal.push({
              allow_access: permiteAcceso,
              number_empty_night: nochesVacio,
              is_notifiable: correoElectronico,
              type_personal_id: tipoColaborador,
            });
          }
        }
      });

      const arrayUser = [];

      const idsRemoveColaboradores = removeColaboradores?.map(
        (item) => item.id
      );

      if (selectedRows.length > 0) {
        const idsSelectedRows = selectedRows?.map((item) => item.id);

        selectedRows.map((item) =>
          arrayUser.push({
            user_id: item.id,
            check: true,
          })
        );

        quarantineUserExceptions?.map((user) => {
          if (idsSelectedRows.includes(user.user_id)) {
          } else {
            arrayUser.push({
              user_id: user.user_id,
              check: false,
            });
          }
        });
      }

      payload = {
        type_quarantine_id: formData.tipoCuarentena,
        pyramid_specie_id: selectedSpecie.id,
        type_personal: type_personal,
        type_personal_remove:
          removeColaboradores.length > 0 ? idsRemoveColaboradores : [],
        description: formData.descripcionCuarentena,
        start_date: moment(formData.inicioTermino[0], "YYYY-MM-DD").format(
          "Y/M/D"
        ),
        end_date: moment(formData.inicioTermino[1], "YYYY-MM-DD").format(
          "Y/M/D"
        ),
        user_exception_in_quarantine: arrayUser,
      };

      if (selectedSpecie.code === "004") {
        payload.food_plant_id = Number(sectorData[0]);
        payload.food_plant_until = formData.sectorPlantasUntil;
      } else {
        payload.sector_id = parseInt(sectorData[2]);
        payload.sectors_until = formData.sector_until;
      }
      await axios
        .patch(
          `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/${params.id}`,
          payload
        )
        .then((response) => {
          if (response.status === 200) {
            setModalInfo({
              open: true,
              type: "success",
              title: "Cuarentena Editada",
              message: "La cuarentena se ha editado correctamente",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          setModalInfo({
            type: "error",
            title: "¡Error!",
            message: err?.response?.data?.error,
          });
        })
        .finally(() => {
          setIsLoading(false);
          setShowModal(true);
          form.resetFields();
        });
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    for (const property in allValues) {
      if (
        property.startsWith("sectorTreeData") &&
        allValues[property] !== undefined
      ) {
        setIsValidSector(true);
      }
    }
  };

  const getSpecies = async () => {
    setIsLoading(true);
    const urlSpecie =
      process.env.REACT_APP_BASE_URL_QUARANTINE +
      "/quarantine/data-master/species";
    axios
      .get(urlSpecie)
      .then((response) => {
        const order = [...response.data].sort((a, b) => a.code - b.code);
        setSpecie(order);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const steps = [
    {
      title: "Paso 1",
      description: "Sector a cuarentenar",
    },
    {
      title: "Paso 2",
      description: "Datos de la cuarentena",
    },
    {
      title: "Paso 3",
      description: "Sectores afectados",
    },
    {
      title: "Paso 4",
      description: "Registro excepciones",
    },
  ];

  const items = () => {
    if (tipoVisita === 1) {
      return steps.map((item) => ({
        key: item.title,
        title: item.title,
        ...item,
      }));
    }
    return steps.map((item) => ({
      key: item.title,
      title: item.title,
      ...item,
    }));
  };

  const prev = () => {
    if (current === 0) {
      navigate("/cuarentenas");
    }

    setCurrent(current - 1);
  };

  return (
    <>
      <AlertModal
        show={showModal}
        title={modalInfo.title}
        type={modalInfo.type}
        onClose={() => navigate("/cuarentenas")}
      >
        {modalInfo.message}
      </AlertModal>
      <div className="crear-declaracion__container cuarentena-stepper">
        <Col md={20}>
          <Steps current={current}>
            {items().map((item) => (
              <Steps.Step
                key={item.key}
                title={item.title}
                description={item.description}
              />
            ))}
          </Steps>
        </Col>
      </div>

      <Form
        layout="vertical"
        form={form}
        onValuesChange={onValuesChange}
        onFinish={(values) => handleFinish(values)}
        requiredMark={false}
      >
        {!isLoading ? (
          <StepperEditar
            tipoVisita={tipoVisita}
            current={current}
            form={form}
            setSelectedSpecie={setSelectedSpecie}
            selectedSpecie={selectedSpecie}
            specie={specie}
            isLoading={isLoading}
            setSelectedDates={setSelectedDates}
            selectedDates={selectedDates}
            setSelectedSector={setSelectedSector}
            selectedSector={selectedSector}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            errorSpecieSelected={errorSpecieSelected}
            seterrorSpecieSelected={seterrorSpecieSelected}
            isValidSector={isValidSector}
            dataDetails={dataDetails}
            formData={formData}
            setAllSectors={setAllSectors}
            allSectors={allSectors}
            colaboradores={colaboradores}
            setColaboradores={setColaboradores}
            removeColaboradores={removeColaboradores}
            setRemoveColaboradores={setRemoveColaboradores}
          />
        ) : (
          <Row
            justify="center"
            vgutter={8}
            className="crear-declaracion__botonera"
          >
            <SpinIcon />
          </Row>
        )}
        <Row
          justify="center"
          vgutter={8}
          className="crear-declaracion__botonera"
        >
          <SecondaryButton width={200} onButtonClick={prev}>
            {current === 0 ? "Cancelar" : "Volver"}
          </SecondaryButton>
          <PrimaryButton htmlType="submit" type="primary" width={200}>
            {current === 3 ? "Finalizar" : "Siguiente"}
          </PrimaryButton>
        </Row>
      </Form>
    </>
  );
};
export default ExistenteCuarentena;
