import {
  CloseOutlined,
  EllipsisOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import { Menu, Table } from "antd";
import axios from "axios";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { capitalize } from "lodash";
import CustomSearch from "components/common/Forms/CustomSearch";
import FiltrosDetalleCuarentenas from "components/Drawer/FiltrosDetalleCuarentenas";

function TablaRegistroColaboradoresCuarentena({
  colaboradores,
  cuarentenaInfo,
  loading,
}) {
  const [fetchConfig, setfetchConfig] = useState({
    limit: 10,
    offset: 1,
  });

  const { state } = useLocation();
  const { cuarentenaId, sectorId } = state;
  const [isDataFetching, setIsDataFetching] = useState(true);

  console.log(state);
  const [data, setData] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [limitRows, setLimitRows] = useState(10);

  const [listUserExceptions, setListUserExceptions] = useState([]);
  const [listUserAccess, setListUserAccess] = useState([]);

  const [isFiltering, setIsFiltering] = useState(false);
  const [users, setusers] = useState([]);
  const [total, setTotal] = useState(0);

  const getData = useCallback(() => {
    setIsDataFetching(true);

    const UsersSector = `${
      process.env.REACT_APP_BASE_URL_QUARANTINE
    }/quarantine/data-master/find-users-by-sector?limit=${
      fetchConfig.limit
    }&offset=${Number(fetchConfig.offset)}&order=asc&code_specie=${
      cuarentenaInfo.specie.code
    }&sectorId=${cuarentenaInfo.sector_id}`;
    const usrSec = axios.get(UsersSector);
    usrSec
      .then((response) => {
        const usersData = response.data.users ?? [];
        const userExceptions = cuarentenaInfo.quarantineUserExceptions ?? [];
        const userAccess = cuarentenaInfo.typePersonalsAccessQuarantines ?? [];

        setListUserExceptions(userExceptions);
        setListUserAccess(userAccess);

        const combinedUsers = usersData.map((user) => {
          const matchingException = userExceptions.find(
            (exception) => exception.user_id === user.person_id
          );
          const matchingAccess = userAccess.find(
            (access) => access.type_personal_id === user.type_personal_id
          );

          if (matchingException) {
            return { ...user, Exception: true };
          }
          if (matchingAccess) {
            return { ...user, allowAccess: true };
          }
          return user;
        });

        setusers(combinedUsers);
        setTotal(response.data.count);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsDataFetching(false);
      });
  }, [loading, cuarentenaInfo, fetchConfig]);

  const handlePaginationChange = ({ current, pageSize }, _) => {
    setfetchConfig({
      ...fetchConfig,
      limit: pageSize,
      offset: current,
    });
  };

  useEffect(() => {
    console.log("estamos aqui");
    // if (!loading) {
    //   getData();
    // }
  }, []);

  const [filterValue, setFilterValue] = useState({
    type_personal: [],
    enterprices: [],
    keyword: "",
    quarantine_id: "",
    sector_id: "",
    currentPageFilter: 1,
  });

  const handleFilterValues = (data = { ...filterValue, limitRows }) => {
    switch (data.remove) {
      case true:
        setCurrentPage(1);
        setIsFiltering(false);
        setLimitRows(10);
        setFilterValue({
          type_personal: [],
          enterprices: [],
          keyword: "",
          quarantine_id: "",
          sector_id: "",
          currentPageFilter: 1,
          currentPageFilter: 1,
        });
        getData({ offset: data.currentPageFilter, limit: 10 });
        setShowDrawer(false);
        break;
      default:
        setFilterValue(data);
        setIsFiltering(data.isFilter ?? true);
        setCurrentPage(data.currentPageFilter);
        setLimitRows(data.limitRows);

        handleFilters(
          {
            ...data,
          },
          data.currentPageFilter,
          data.limitRows
        );
        setShowDrawer(false);
        break;
    }
  };

  const handleFilters = async (
    filter = {
      type_personal: [],
      enterprices: [],
      keyword: "",
      quarantine_id: "",
      sector_id: "",
    },
    offset = 1,
    limit = 10,
    order = "asc"
  ) => {
    setIsDataFetching(true);
    try {
      let filters = {};

      if (filter?.type_personal !== "") {
        filters.type_personal = filter?.type_personal;
      }

      if (filter?.enterprices !== "") {
        filters.enterprices = filter?.enterprices;
      }
      if (filter?.keyword !== "") {
        filters.keyword = filter?.keyword;
      }

      filters.quarantine_id = filter?.quarantine_id;
      filters.sector_id = filter?.sector_id;

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/details/filter-advanced`,
        { offset: offset, limit: limit, order: order, ...filters }
      );

      const countRows = response.data.count ?? 0;
      const usersData = response.data.users ?? [];

      const combinedUsers = usersData.map((user) => {
        const matchingException = listUserExceptions.find(
          (exception) => exception.user_id === user.person_id
        );
        const matchingAccess = listUserAccess.find(
          (access) => access.type_personal_id === user.type_personal_id
        );

        if (matchingException) {
          return { ...user, Exception: true };
        }
        if (matchingAccess) {
          return { ...user, allowAccess: true };
        }
        return user;
      });

      setusers(combinedUsers);
      setTotal(countRows);
    } catch (error) {
      console.error(error);
      setusers([]);
    } finally {
      setIsDataFetching(false);
    }
  };

  const [shouldReset, setShouldReset] = useState(false);

  const openFilterDrawer = () => {
    setShowDrawer(true);
  };
  const onClose = () => {
    setShowDrawer(false);
  };

  const getFilterResults = (data) => {
    setData(data);
  };
  const handleResetFilters = () => {
    setSearchValue("");
    setCurrentPage(1);
    setShouldReset(true);
  };

  const formatDate = (dateTimestamp) => {
    let date = new Date();
    date.setTime(dateTimestamp);
    return moment(date).format("DD/MM/YYYY [a las] HH:mm [hrs]");
  };

  const actionButton = {
    width: "30px",
    height: "30px",
    background: "rgba(0,48,135,.08)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
  };
  const menuItem = (record) => (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Link
              state={{ cuarentenaId: record.id }}
              to={`/cuarentenas/${record.id}/ver-detalle`}
            >
              Ver Detalle
            </Link>
          ),
        },
        {
          key: "2",
          label: (
            <Link state={{ cuarentenaId: record }} to="#">
              Invalidar
            </Link>
          ),
        },
      ]}
    />
  );

  const columns = [
    {
      title: "ID",
      editable: true,
      render: (record) => record.id,
    },
    {
      title: "Nombre",
      render: (record) => (
        <span>{`${capitalize(record.person.name)} ${capitalize(
          record.person.lastname
        )}`}</span>
      ),
    },
    {
      title: "Rut empresa",
      render: (record) =>
        record.enterprice.length > 0
          ? capitalize(record.enterprice[0].rut)
          : "",
    },
    {
      title: "R Social",
      render: (record) =>
        record.enterprice.length > 0
          ? capitalize(record.enterprice[0].social_reason)
          : "",
    },
    {
      title: "Tipo de Colaborador",
      render: (record) =>
        record.typePersonal !== null
          ? capitalize(record.typePersonal.name_type_personal)
          : "",
    },
    {
      title: "Excepción",
      render: (record) => (record.Exception === true ? "SI" : "NO"),
    },
    {
      title: "Permite Acceso",
      render: (record) => (record.allowAccess === true ? "SI" : "NO"),
    },
    /*{
      title: "Acción",
      render: (record) => (
        <div style={actionButton}>
          <Dropdown overlay={menuItem(record)} trigger={["click"]}>
            <Space>
              <EllipsisOutlined rotate={90} />
            </Space>
          </Dropdown>
        </div>
      ),
    },*/
  ];

  return (
    <div className="registro-sectores-table">
      <div className="filter-container" style={{ backgroundColor: "#fff" }}>
        <div className="filters">
          <div className="search-filter">
            <CustomSearch
              loading={isDataFetching}
              disabled={isDataFetching}
              value={searchValue}
              placeholder="Buscar"
              onChange={(e) => setSearchValue(e.target.value)}
              onSearch={() => {
                handleFilterValues({
                  ...filterValue,
                  keyword: searchValue,
                });
              }}
            />
          </div>
          <div className="drawer-filter-btn">
            <SecondaryButton onButtonClick={openFilterDrawer} width={90}>
              Filtros
            </SecondaryButton>
          </div>
          {isFiltering ? (
            <PrimaryButton
              className="mobile-filters"
              onButtonClick={handleResetFilters}
            >
              <CloseOutlined />
            </PrimaryButton>
          ) : null}
        </div>

        <div
          className="register-movement-btn"
          style={{
            display: "flex",
            gap: "5px",
          }}
        ></div>
      </div>
      <Table
        loading={isDataFetching}
        columns={columns}
        dataSource={users}
        pagination={{
          showTotal: (_total, range) =>
            `${range[0]}-${range[1]} de ${total} registros`,
          defaultPageSize: fetchConfig.limit,
          total: total,
          responsive: true,
        }}
        onChange={(selection) => {
          if (isFiltering) {
            handleFilterValues({
              ...filterValue,
              keyword: searchValue,
              currentPageFilter: selection.current,
              limitRows: selection.pageSize,
              isFilter: isFiltering,
            });
          } else {
            handlePaginationChange(selection);
            setCurrentPage(selection.current);
          }
        }}
      />
      <FiltrosDetalleCuarentenas
        onClose={onClose}
        visible={showDrawer}
        isDataFetching={isDataFetching}
        searchValue={searchValue}
        shouldReset={shouldReset}
        setShouldReset={setShouldReset}
        setIsFiltering={setIsFiltering}
        setIsDataFetching={setIsDataFetching}
        setTotal={setTotal}
        handleFilterValues={handleFilterValues}
        cuarentenaId={cuarentenaId}
        sectorId={sectorId}
      />
    </div>
  );
}

export default TablaRegistroColaboradoresCuarentena;
