import { Table } from 'antd'
import axios from 'axios'
import { SecondaryButton } from 'components/common/Buttons'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { capitalize } from 'lodash'
import CustomSearch from 'components/common/Forms/CustomSearch'
import FiltrosTablaCuarentenas from 'components/Drawer/FiltrosTablaCuarentenas'

function TablaRegistroSectoresCuarentena({ sectors }) {
  const { state } = useLocation()
  const { cuarentenaId } = state
  const [tableData, setTableData] = useState([])
  const [isDataFetching, setIsDataFetching] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [sectoresData, setSectoresData] = useState([])
  const [data, setData] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)

  const [showDHistory, setshowDHistory] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const [shouldReset, setShouldReset] = useState(false)

  const openFilterDrawer = () => {
    setShowDrawer(true)
  }
  const onClose = () => {
    setShowDrawer(false)
  }

  const getFilterResults = (data) => {
    setData(data)
  }

  const columns = [
    {
      title: 'ID',
      editable: true,
      render: (record) => record.id
    },
    {
      title: 'Nombre',
      render: (record) => capitalize(record.name)
    },
    {
      title: 'Rut empresa',
      dataIndex: 'rutEmpresa'
    },
    {
      title: 'R Social',
      dataIndex: 'rSocial'
    },
    {
      title: 'Estado',
      dataIndex: 'estado'
    },
    {
      title: 'Fecha Inicio',
      dataIndex: 'fechaInicio'
    },
    {
      title: 'Fecha Termino',
      dataIndex: 'fechaTermino'
    }
  ]

  const getSectoresCuarentenaByID = async (
    params = { offset: 1, limit: 10, order: 'DESC' }
  ) => {
    setIsDataFetching(true)
    try {
      let response = undefined

      response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_QUARANTINE}/quarantine/${cuarentenaId}`
      )

      const data = response.data.quarantines ?? []
      const countRows = response.data.count ?? 0
      setSectoresData(data.sectors_until)
      setTableData(data)
      setIsDataFetching(false)
    } catch (error) {
      console.error(error)
      setIsDataFetching(false)
    }
  }

  useEffect(() => {
    getSectoresCuarentenaByID({
      offset: 1,
      limit: 10,
      quarantineId: cuarentenaId
    })
  }, [cuarentenaId])

  console.log(tableData)

  return (
    <div className='registro-sectores-table'>
      <div className='filter-container' style={{ backgroundColor: '#fff' }}>
        <div className='filters'>
          <div className='search-filter'>
            <CustomSearch
              loading={isSearching || isDataFetching}
              disabled={isSearching || isDataFetching}
            />
          </div>
          <div className='drawer-filter-btn'>
            <SecondaryButton onButtonClick={openFilterDrawer} width={90}>
              Filtros
            </SecondaryButton>
          </div>
        </div>

        <div
          className='register-movement-btn'
          style={{
            display: 'flex',
            gap: '5px'
          }}
        ></div>
      </div>
      <Table
        loading={isDataFetching || isSearching}
        columns={columns}
        dataSource={sectors}
      />
      <FiltrosTablaCuarentenas
        onClose={onClose}
        visible={showDrawer}
        filterResults={getFilterResults}
        specieId='001'
      />
    </div>
  )
}

export default TablaRegistroSectoresCuarentena
