import React, { useEffect, useState } from "react";
import { Row, Card, Divider, Col, Checkbox, Form, Select, Alert } from "antd";

export default function DeclaracionJuradaIngresoExterno({
  form,
  selectedSpecie,
  selectedLanguage,
  setSelectedLanguage,
  agreeCheck,
  setAgreeCheck,
  messageAgreeError,
  setMessageAgreeError,
  valueSelectPlants,
  allPlants,
}) {
  const { Option } = Select;

  const onChange = (value) => {
    setSelectedLanguage(value);
  };

  const onChangeCheck = (e) => {
    setAgreeCheck(e.target.checked);
    setMessageAgreeError(false);
  };

  const allFoodPlantsListPigs = [
    27, // Lo Miranda
    28, // Longovilo
    29, // Casablanca
    31, // La Estrella
    29, // Casablanca
    30, // La Calera
  ];

  const allFoodListPavo = [
    29, // Casablanca
    30, // La Calera
  ];

  const statements = (specie) => {
    switch (specie) {
      case "001":
        if (selectedLanguage === 1) {
          return (
            <>
              <li>
                Han transcurrido 5 o más noches desde el contacto de la visita
                con cerdos y/o aves según corresponda, ajenos a{" "}
                <strong>Agrícola Super Limitada</strong>.
              </li>
              <li>
                Han transcurrido 5 o más noches, desde que la visita tuvo
                contacto con Plantas Faenadoras y elaboradoras de productos
                alimentos de origen animal ajenas a{" "}
                <strong>Agrícola Super Limitada</strong>.
              </li>
              <li>
                Han transcurrido 5 o más noches, desde que la visita tuvo
                contacto con ferias ganaderas, granjas educativas y zoológicos.
              </li>
              <li>
                La visita no ha presentado cuadro febril, 48 hrs previas a la
                visita solicitada.
              </li>
              <li>
                (solo si aplica) He realizado el curso de Bioseguridad. Si la
                respuesta es SI, debe continuar con el paso siguiente. Si la
                respuesta es NO, se debe poder acceder al link del curso para
                que el usuario lo vea en el formato definido (video, PDF etc.)
                Sin realizar el curso, no debe poder continuar. El curso se debe
                realizar una sola vez por Rut, a no ser que se haya actualizado
                y tenga una nueva versión.
              </li>
            </>
          );
        } else {
          return (
            <>
              <li>
                5 or more nights have passed since the visit came into contact
                with pigs and/or birds as appropriate, other than{" "}
                <strong>Agrícola Super Limitada</strong>.
              </li>
              <li>
                5 or more nights have passed since the visit had contact with
                Slaughter Plants and producers of food products of animal origin
                outside of <strong>Agrícola Super Limitada</strong>.
              </li>
              <li>
                5 or more nights have passed since the visit had contact with
                livestock fairs, educational farms and zoos.
              </li>
              <li>
                The visit has not presented fever, 48 hours prior to the
                requested visit.
              </li>
              <li>
                (only if applicable) I have taken the Bioseguridad course. If
                the answer is YES, you must continue with the next step. If the
                answer is NO, the course link must be accessible so that the
                user can see it in the defined format (video, PDF, etc.).
                Without taking the course, they must not be able to continue.
                The course must be taken only once per Ruth, unless it has been
                updated and has a new version.
              </li>
            </>
          );
        }

      case "002":
        if (selectedLanguage === 1) {
          return (
            <>
              <li>
                Durante las últimas 5 noches no he tenido contacto con pollos,
                gallinas, pavos, u otro tipo de aves, ni cerdos.
              </li>
              <li>
                Durante las últimas 5 noches no he tenido contacto con plantas
                de alimentos, mataderos y elaboradora de productos alimenticios
                de origen animal.
              </li>
              <li>
                No poseo pollos, gallinas o pavos ni otro tipo de aves, ni
                cerdos en mi hogar.{" "}
              </li>
              <li>
                Cumpliré sin excepción todas las medidas de bioseguridad
                impuestas por <strong>Sopraval SpA</strong>.{" "}
              </li>
              <li>
                Fui vacunado contra la Influenza estacional dentro del último
                año.{" "}
              </li>
              <li>
                (solo si aplica) He realizado el curso de Bioseguridad. Si la
                respuesta es SI, debe continuar con el paso siguiente. Si la
                respuesta es NO, se debe poder acceder al link del curso para
                que el usuario vea el curso en el formato definido (video, PDF
                etc.) Sin realizar el curso, no debe poder continuar.
              </li>
            </>
          );
        } else {
          return (
            <>
              <li>
                During the last 5 nights I have not had contact with chickens,
                hens, turkeys, or other types of birds, or pigs.
              </li>
              <li>
                During the last 5 nights I have not had contact with food
                plants, slaughterhouses and producers of food products of animal
                origin.{" "}
              </li>
              <li>
                I do not have chickens, hens or turkeys or other types of birds,
                or pigs in my home.{" "}
              </li>
              <li>
                I will comply without exception with all biosafety measures
                imposed by <strong>Sopraval SpA</strong>.{" "}
              </li>
              <li>
                I was vaccinated against seasonal influenza within the last year{" "}
              </li>
              <li>
                (only if applicable) I have taken the Biosafety course. If the
                answer is YES, you must continue with the next step. If the
                answer is NO, the course link must be accessible so that the
                user can see the course in the defined format (video, PDF,
                etc.). Without taking the course, they must not be able to
                continue.{" "}
              </li>
            </>
          );
        }
      case "003":
        if (selectedLanguage === 1) {
          return (
            <>
              <li>
                Han transcurrido 5 o más noches desde el contacto de la visita
                con cerdos y/o aves según corresponda, ajenos a{" "}
                <strong>Agrícola Super Limitada</strong>.
              </li>
              <li>
                Han transcurrido 5 o más noches, desde que la visita tuvo
                contacto con Plantas Faenadoras y elaboradoras de productos
                alimentos de origen animal ajenas a{" "}
                <strong>Agrícola Super Limitada</strong>.
              </li>
              <li>
                Han transcurrido 5 o más noches, desde que la visita tuvo
                contacto con ferias ganaderas, granjas educativas y zoológicos.
              </li>
              <li>
                La visita no ha presentado cuadro febril, 48 hrs previas a la
                visita solicitada.
              </li>
              <li>
                (solo si aplica) He realizado el curso de Bioseguridad. Si la
                respuesta es SI, debe continuar con el paso siguiente. Si la
                respuesta es NO, se debe poder acceder al link del curso para
                que el usuario lo vea en el formato definido (video, PDF etc.)
                Sin realizar el curso, no debe poder continuar. El curso se debe
                realizar una sola vez por Rut, a no ser que se haya actualizado
                y tenga una nueva versión.
              </li>
            </>
          );
        } else {
          return (
            <>
              <li>
                5 or more nights have passed since the visit came into contact
                with pigs and/or birds as appropriate, other than{" "}
                <strong>Agrícola Super Limitada</strong>.
              </li>
              <li>
                5 or more nights have passed since the visit had contact with
                Slaughter Plants and producers of food products of animal origin
                outside of <strong>Agrícola Super Limitada</strong>.
              </li>
              <li>
                5 or more nights have passed since the visit had contact with
                livestock fairs, educational farms and zoos.
              </li>
              <li>
                The visit has not presented fever, 48 hours prior to the
                requested visit.
              </li>
              <li>
                (only if applicable) I have taken the Bioseguridad course. If
                the answer is YES, you must continue with the next step. If the
                answer is NO, the course link must be accessible so that the
                user can see it in the defined format (video, PDF, etc.).
                Without taking the course, they must not be able to continue.
                The course must be taken only once per Ruth, unless it has been
                updated and has a new version.
              </li>
            </>
          );
        }
      case "004":
        if (selectedLanguage === 1) {
          if (allPlants) {
            return (
              <>
                <li>
                  Han transcurrido 5 o más noches desde el contacto de la visita
                  con cerdos y/o aves según corresponda, ajenos a{" "}
                  <strong>Agrícola Super Limitada</strong>.
                </li>
                <li>
                  Han transcurrido 5 o más noches, desde que la visita tuvo
                  contacto con Plantas Faenadoras y elaboradoras de productos
                  alimentos de origen animal ajenas a{" "}
                  <strong>Agrícola Super Limitada</strong>.
                </li>
                <li>
                  Han transcurrido 5 o más noches, desde que la visita tuvo
                  contacto con ferias ganaderas, granjas educativas y
                  zoológicos.
                </li>
                <li>
                  La visita no ha presentado cuadro febril, 48 hrs previas a la
                  visita solicitada.
                </li>
                <li>
                  No poseo pollos, gallinas o pavos ni otro tipo de aves, ni
                  cerdos en mi hogar.{" "}
                </li>
                <li>
                  Cumpliré sin excepción todas las medidas de bioseguridad
                  impuestas por <strong>Sopraval SpA</strong>.{" "}
                </li>
                <li>
                  Fui vacunado contra la Influenza estacional dentro del último
                  año.{" "}
                </li>
                <li>
                  (solo si aplica) He realizado el curso de Bioseguridad. Si la
                  respuesta es SI, debe continuar con el paso siguiente. Si la
                  respuesta es NO, se debe poder acceder al link del curso para
                  que el usuario lo vea en el formato definido (video, PDF etc.)
                  Sin realizar el curso, no debe poder continuar. El curso se
                  debe realizar una sola vez por Rut, a no ser que se haya
                  actualizado y tenga una nueva versión.
                </li>
              </>
            );
          } else {
            return (
              <>
                <li>
                  Han transcurrido 5 o más noches desde el contacto de la visita
                  con cerdos y/o aves según corresponda, ajenos a{" "}
                  <strong>Agrícola Super Limitada</strong>.
                </li>
                <li>
                  Han transcurrido 5 o más noches, desde que la visita tuvo
                  contacto con Plantas Faenadoras y elaboradoras de productos
                  alimentos de origen animal ajenas a{" "}
                  <strong>Agrícola Super Limitada</strong>.
                </li>
                {allFoodPlantsListPigs.includes(valueSelectPlants) && (
                  <>
                    <li>
                      Han transcurrido 5 o más noches, desde que la visita tuvo
                      contacto con ferias ganaderas, granjas educativas y
                      zoológicos.
                    </li>
                    <li>
                      La visita no ha presentado cuadro febril, 48 hrs previas a
                      la visita solicitada.
                    </li>
                  </>
                )}
                {allFoodListPavo.includes(valueSelectPlants) && (
                  <>
                    <li>
                      No poseo pollos, gallinas o pavos ni otro tipo de aves, ni
                      cerdos en mi hogar.{" "}
                    </li>
                    <li>
                      Cumpliré sin excepción todas las medidas de bioseguridad
                      impuestas por <strong>Sopraval SpA</strong>.{" "}
                    </li>
                    <li>
                      Fui vacunado contra la Influenza estacional dentro del
                      último año.{" "}
                    </li>
                  </>
                )}
                <li>
                  (solo si aplica) He realizado el curso de Bioseguridad. Si la
                  respuesta es SI, debe continuar con el paso siguiente. Si la
                  respuesta es NO, se debe poder acceder al link del curso para
                  que el usuario lo vea en el formato definido (video, PDF etc.)
                  Sin realizar el curso, no debe poder continuar. El curso se
                  debe realizar una sola vez por Rut, a no ser que se haya
                  actualizado y tenga una nueva versión.
                </li>
              </>
            );
          }
        } else {
          if (allPlants) {
            return (
              <>
                <li>
                  5 or more nights have passed since the visit came into contact
                  with pigs and/or birds as appropriate, other than{" "}
                  <strong>Agrícola Super Limitada</strong>.
                </li>
                <li>
                  5 or more nights have passed since the visit had contact with
                  Slaughter Plants and producers of food products of animal
                  origin outside of <strong>Agrícola Super Limitada</strong>.
                </li>
                <li>
                  5 or more nights have passed since the visit had contact with
                  livestock fairs, educational farms and zoos.
                </li>
                <li>
                  The visit has not presented fever, 48 hours prior to the
                  requested visit.
                </li>
                <li>
                  I do not have chickens, hens or turkeys or other types of
                  birds, or pigs in my home.{" "}
                </li>
                <li>
                  I will comply without exception with all biosafety measures
                  imposed by <strong>Sopraval SpA</strong>.{" "}
                </li>
                <li>
                  I was vaccinated against seasonal influenza within the last
                  year{" "}
                </li>
                <li>
                  (only if applicable) I have taken the Bioseguridad course. If
                  the answer is YES, you must continue with the next step. If
                  the answer is NO, the course link must be accessible so that
                  the user can see it in the defined format (video, PDF, etc.).
                  Without taking the course, they must not be able to continue.
                  The course must be taken only once per Ruth, unless it has
                  been updated and has a new version.
                </li>
              </>
            );
          } else {
            return (
              <>
                <li>
                  5 or more nights have passed since the visit came into contact
                  with pigs and/or birds as appropriate, other than{" "}
                  <strong>Agrícola Super Limitada</strong>.
                </li>
                <li>
                  5 or more nights have passed since the visit had contact with
                  Slaughter Plants and producers of food products of animal
                  origin outside of <strong>Agrícola Super Limitada</strong>.
                </li>
                {allFoodPlantsListPigs.includes(valueSelectPlants) && (
                  <>
                    <li>
                      5 or more nights have passed since the visit had contact
                      with livestock fairs, educational farms and zoos.
                    </li>
                    <li>
                      The visit has not presented fever, 48 hours prior to the
                      requested visit.
                    </li>
                  </>
                )}
                {allFoodListPavo.includes(valueSelectPlants) && (
                  <>
                    <li>
                      I do not have chickens, hens or turkeys or other types of
                      birds, or pigs in my home.{" "}
                    </li>
                    <li>
                      I will comply without exception with all biosafety
                      measures imposed by <strong>Sopraval SpA</strong>.{" "}
                    </li>
                    <li>
                      I was vaccinated against seasonal influenza within the
                      last year{" "}
                    </li>
                  </>
                )}
                <li>
                  (only if applicable) I have taken the Bioseguridad course. If
                  the answer is YES, you must continue with the next step. If
                  the answer is NO, the course link must be accessible so that
                  the user can see it in the defined format (video, PDF, etc.).
                  Without taking the course, they must not be able to continue.
                  The course must be taken only once per Ruth, unless it has
                  been updated and has a new version.
                </li>
              </>
            );
          }
        }
      default:
        return "-";
    }
  };

  return (
    <>
      <div className="declaracion-visitas__container">
        <Col md={20}>
          <Card className="declaracion-visitas__contenedor">
            <Row>
              <Col span={20}>
                {selectedLanguage === 1 ? (
                  <div className="declaracion-visitas__title">
                    Declaración{" "}
                    <span style={{ fontWeight: "600" }}>
                      Visitas Nacionales e Internacionales
                    </span>
                  </div>
                ) : (
                  <div className="declaracion-visitas__title">
                    Declaration{" "}
                    <span style={{ fontWeight: "600" }}>
                      National and International Visits
                    </span>
                  </div>
                )}
              </Col>
              <Col span={4}>
                <Form.Item
                  name="language"
                  label={selectedLanguage === 1 ? "Idioma:" : "Language:"}
                  className="declaracion-visitas__language"
                >
                  <Select
                    onChange={onChange}
                    defaultValue={selectedLanguage}
                    value={selectedLanguage}
                  >
                    <Option value={1}>Español / Spanish</Option>
                    <Option value={2}>Inglés / English</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Divider />
            <Row>
              <Col className="declaracion-visitas__content" span={24}>
                {selectedLanguage === 1 ? (
                  <p className="declaracion-visitas__title-declaracion-visita">
                    Declaro que:
                  </p>
                ) : (
                  <p className="declaracion-visitas__title-declaracion-visita">
                    I declare that:
                  </p>
                )}

                <ul className="declaracion-visitas__price-features">
                  {statements(selectedSpecie)}
                </ul>
                <div className="declaracion-visitas__content-check">
                  <Checkbox onChange={onChangeCheck} checked={agreeCheck}>
                    {selectedLanguage === 1 ? (
                      <p className="declaracion-visitas__text-ckeck">
                        <strong>
                          Declaro haber leído y estar de acuerdo en su totalidad
                          con los prerrequisitos expuestos anteriormente
                        </strong>
                      </p>
                    ) : (
                      <p className="declaracion-visitas__text-ckeck">
                        <strong>
                          I declare that I have read and agree in its entirety
                          with the prerequisites set out above
                        </strong>
                      </p>
                    )}
                  </Checkbox>
                </div>
                <br />
                <div className="declaracion-visitas__messageAgreeError">
                  {messageAgreeError && (
                    <Alert
                      message="Debes aceptar la declaración para continuar."
                      type="error"
                      showIcon
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </div>
    </>
  );
}
