import {
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Tabs,
  TreeSelect,
  Spin,
  Checkbox,
} from "antd";
import axios from "axios";
import { BackSvdIcon } from "components/common/Icons/Icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "components/common/Buttons";
import { LoadingOutlined } from "@ant-design/icons";
import { AlertModal } from "components/common/modals/modals";
import es_ES from "antd/lib/locale/es_ES";

const ExcepcionEspecieTab = ({
  settype,
  type,
  form,
  selectedFoodPlant,
  setSelectedFoodPlant,
  setselectedSpecie,
  selectedSpecie,
}) => {
  const location = useLocation();
  const { state } = location;
  const [allFoodPlants, setAllFoodPlants] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const [niveles, setniveles] = useState([]);
  const [zonas, setzonas] = useState([]);
  const [sector, setsector] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = useState(null);

  const { TabPane } = Tabs;

  const plainOptions = useMemo(() => {
    return allFoodPlants.map((foodPlant) => {
      return {
        label: foodPlant.name,
        value: foodPlant.id,
      };
    });
  }, [allFoodPlants]);

  const spinIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );

  const onCheckAllChange = (e) => {
    setSelectedFoodPlant(
      e.target.checked ? plainOptions.map((item) => item.value) : []
    );
    setCheckAll(e.target.checked);
    setIndeterminate(!e.target.checked);
  };

  const setInfo = useCallback(async () => {
    setIsLoading(true);
    const nivelURL = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findlevel?code_specie=${selectedSpecie}`;
    const zonaURL = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findzone?code_specie=${selectedSpecie}`;
    const sectorURL = `${process.env.REACT_APP_BASE_URL_REQUESTS}/data-master/findsector?code_specie=${selectedSpecie}`;
    const plantasURL = `${process.env.REACT_APP_BASE_URL_FOOD_PLANT}/food-plant`;

    const niv = axios.get(nivelURL);
    const zon = axios.get(zonaURL);
    const sec = axios.get(sectorURL);
    const pla = axios.get(plantasURL);

    await axios
      .all([niv, zon, sec, pla])
      .then(
        axios.spread((...responses) => {
          const activeLevels = responses[0].data.filter(
            (level) => level.status_id === 1
          );
          setniveles(activeLevels);

          const activeZones = responses[1].data.filter(
            (zone) => zone.status_id === 1
          );
          setzonas(activeZones);

          const activeSectors = responses[2].data.filter(
            //sectores desactivados ??
            (sector) => sector.status_id !== "6"
          );
          setsector(activeSectors);

          const activePlants = responses[3].data.list.filter(
            (plant) => Number(plant.status_id) === 1
          );
          setAllFoodPlants(activePlants);
        })
      )
      .catch((error) => console.log(error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedSpecie]);

  const handleSelectTab = (key) => {
    settype();

    if (key === "1") {
      setselectedSpecie("001");
    } else if (key === "2") {
      setselectedSpecie("003");
    } else if (key === "3") {
      setselectedSpecie("002");
    } else if (key === "4") {
      setselectedSpecie("004");
    }
    setSelectedFoodPlant([]);
  };

  const selectFoodPlant = (value) => {
    setSelectedFoodPlant(value);
  };

  const handleType = (e) => {
    settype(e.target.value);
  };

  const getSector = (zona, nivel) => {
    let childrenTreeData = [];
    const SectorFiltred = sector.filter(
      (sector) => sector.level_id === nivel.id && sector.zone_id === zona.id
    );
    SectorFiltred.map((sector) => {
      let tempTreeDataSector = {
        title: `${sector.name}`,
        value: `${nivel.id}-${zona.id}-${sector.id}`,
        key: `${nivel.id}-${zona.id}-${sector.id}`,
        disabled: sector.status_id === 2 ? true : false,
      };

      childrenTreeData.push(tempTreeDataSector);
    });

    return childrenTreeData;
  };

  const getOptionsSelect = (nivel) => {
    let treeData = [];
    const zonesFiltered = zonas.filter((zone) =>
      zone.level_id.flat().find((i) => Number(i) === nivel.id)
    );

    zonesFiltered.map((zone) => {
      const childrenSectors = getSector(zone, nivel);
      if (childrenSectors.length > 0) {
        let tempData = {
          title: `${zone.name}`,
          value: `${nivel.id}-${zone.id}`,
          key: `${nivel.id}-${zone.id}`,
          children: childrenSectors,
          disabled: zone.status_id === 2 ? true : false,
        };

        treeData.push(tempData);
      }
    });
    return treeData;
  };

  const getOptionSelectZone = (zona) => {
    let treeData = [];
    const nivelesZone = zona.level_id.flat();
    nivelesZone.map((nivel) => {
      const nivelFound = niveles.find(
        (nivelFind) => nivelFind.id === Number(nivel)
      );
      if (nivelFound !== undefined) {
        const childrenSectors = getSector(zona, nivelFound);
        if (childrenSectors.length > 0) {
          let tempTree = {
            title: `${nivelFound.name}`,
            value: `${zona.id}-${nivel}`,
            key: `${zona.id}-${nivel}`,
            children: childrenSectors,
            disabled: nivelFound.status_id === 2 ? true : false,
          };
          treeData.push(tempTree);
        }
      }
    });
    return treeData;
  };

  const optionsSelect = () => {
    const options = sector.map((sec) => {
      return {
        label: `${sec.level.name} - ${sec.zone.name} - ${sec.name}`,
        value: `${sec.id}-${sec.level.id}-${sec.zone.id}`,
        disabled: sec.status_id === 2 ? true : false,
      };
    });

    return options;
  };

  const typeForm = (type) => {
    let layout;
    if (type === "nivel") {
      if (isLoading) {
        layout = (
          <Col xs={12} md={12} lg={12}>
            <Spin indicator={spinIcon} />
          </Col>
        );
      } else {
        layout = niveles?.map((n, index) => {
          let treeData = getOptionsSelect(n);
          if (treeData.length > 0) {
            return (
              <Col xs={12} md={12} lg={12} key={index}>
                <Form.Item
                  name={`selectorNivel-${n.id}`}
                  label={n.name}
                  key={index}
                >
                  <TreeSelect
                    showSearch
                    filterTreeNode={(search, item) => {
                      return (
                        item.title
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                      );
                    }}
                    showCheckedStrategy="SHOW_PARENT"
                    treeData={treeData}
                    treeCheckable={true}
                  ></TreeSelect>
                </Form.Item>
              </Col>
            );
          }
        });
      }
    } else if (type === "zona") {
      if (isLoading) {
        layout = (
          <Col xs={12} md={12} lg={12}>
            <Spin indicator={spinIcon} />
          </Col>
        );
      } else {
        layout = zonas?.map((z, i) => {
          let treeData = getOptionSelectZone(z);
          if (treeData.length > 0) {
            return (
              <Col xs={12} md={12} lg={12} key={i}>
                <Form.Item
                  name={`selectorZona-${z.id}`}
                  label={`${z.name}`}
                  key={i}
                >
                  <TreeSelect
                    showSearch
                    filterTreeNode={(search, item) => {
                      return (
                        item.title
                          .toLowerCase()
                          .indexOf(search.toLowerCase()) >= 0
                      );
                    }}
                    showCheckedStrategy="SHOW_PARENT"
                    treeData={treeData}
                    treeCheckable={true}
                  ></TreeSelect>
                </Form.Item>
              </Col>
            );
          }
        });
      }
    } else if (type === "sector") {
      if (isLoading) {
        layout = (
          <Col xs={12} md={12} lg={12}>
            <Spin indicator={spinIcon} />
          </Col>
        );
      } else {
        layout = (
          <Col xs={24} md={24} lg={24}>
            <Form.Item
              name="SelectorSector"
              label="Seleccione un sector para editar"
            >
              <Select
                showSearch
                mode="multiple"
                options={optionsSelect()}
                filterOption={(search, item) => {
                  return (
                    item.label.toLowerCase().indexOf(search.toLowerCase()) >= 0
                  );
                }}
                onChange={onchange}
                value={value}
              />
            </Form.Item>
          </Col>
        );
      }
    }

    return layout;
  };

  useEffect(() => {
    setInfo();
  }, [setInfo]);

  useEffect(() => {
    setIndeterminate(
      selectedFoodPlant.length > 0 &&
        selectedFoodPlant.length < plainOptions.length
    );
    setCheckAll(selectedFoodPlant.length === plainOptions.length);
  }, [plainOptions, selectedFoodPlant]);

  return (
    <>
      <Form layout="vertical" form={form}>
        <div className="crear-excepcion__container">
          <Col md={20}>
            <div className="card-container">
              <Tabs
                type="card"
                tabBarGutter={10}
                onChange={handleSelectTab}
                defaultActiveKey={state || "1"}
              >
                <TabPane tab="Cerdos" key="1">
                  <div>
                    <span>Selecciona un area para visualizar</span>
                  </div>
                  <div style={{ margin: "8px 0 16px" }}>
                    <Radio.Group onChange={handleType} value={type}>
                      <Radio value="nivel">Nivel</Radio>
                      <Radio value="zona">Zona</Radio>
                      <Radio value="sector">Sector</Radio>
                      <Radio value="all">Acceso total</Radio>
                    </Radio.Group>
                  </div>
                  <Row gutter={[32, 16]}>{typeForm(type)}</Row>
                </TabPane>
                <TabPane tab="Pollos" key="2">
                  <div>
                    <span>Selecciona un area para visualizar</span>
                  </div>
                  <div style={{ margin: "8px 0 16px" }}>
                    <Radio.Group onChange={handleType} value={type}>
                      <Radio value="nivel">Nivel</Radio>
                      <Radio value="zona">Zona</Radio>
                      <Radio value="sector">Sector</Radio>
                      <Radio value="all">Acceso total</Radio>
                    </Radio.Group>
                  </div>
                  <Row gutter={[32, 16]}>{typeForm(type)}</Row>
                </TabPane>

                <TabPane tab="Pavos" key="3">
                  <div>
                    <span>Selecciona un area para visualizar</span>
                  </div>
                  <div style={{ margin: "8px 0 16px" }}>
                    <Radio.Group onChange={handleType} value={type}>
                      <Radio value="nivel">Nivel</Radio>
                      <Radio value="zona">Zona</Radio>
                      <Radio value="sector">Sector</Radio>
                      <Radio value="all">Acceso total</Radio>
                    </Radio.Group>
                  </div>
                  <Row gutter={[32, 16]}>{typeForm(type)}</Row>
                </TabPane>
                <TabPane tab="Planta de alimentos" key="4">
                  <div>
                    <span>Selecciona un area para visualizar</span>
                  </div>
                  <div style={{ margin: "8px 0 16px" }}>
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={onCheckAllChange}
                      checked={checkAll}
                    >
                      Acceso total
                    </Checkbox>
                    <Divider />
                    <Checkbox.Group
                      onChange={selectFoodPlant}
                      value={selectedFoodPlant}
                    >
                      {plainOptions.map((foodPlant) => (
                        <Checkbox key={foodPlant.value} value={foodPlant.value}>
                          {foodPlant.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </div>
                  <Row gutter={[32, 16]}>{typeForm(type)}</Row>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </div>
      </Form>
    </>
  );
};

export default ExcepcionEspecieTab;
