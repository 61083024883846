import { CloseOutlined, RedoOutlined } from '@ant-design/icons'
import { Form, Table } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { capitalize } from 'utils/Capitalize 2'
import moment from 'moment'
import {
  AlimentoIcon,
  CerdoIcon,
  PavoIcon,
  PolloIcon
} from 'components/common/Icons/Icons'
import FiltroTablaMovimientos from 'components/Drawer/FiltroTablaMovimientos'
import { PrimaryButton, SecondaryButton } from 'components/common/Buttons'
import { useMovimientos } from './hooks/useMovimientos'
import CustomSearch from 'components/common/Forms/CustomSearch'
import { useRut } from 'utils/FormatRut'
import { AppContext } from 'app/AppContext'
import { userHasPermission } from 'utils/userHasPermission'
import { MOVIMIENTOS_USUARIOS } from 'app_permissions/dashboard'

const TablaMovimientosGenerales = ({ setFilter, setIsLoading }) => {
  const [form] = Form.useForm()
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [limitRows, setLimitRows] = useState(10)

  const { rolData } = useContext(AppContext)

  const VER_MOV_USUARIOS = userHasPermission(
    rolData?.role,
    MOVIMIENTOS_USUARIOS.VISUALIZAR
  )

  const {
    tableData,
    isSearching,
    isDataFetching,
    total,
    isFiltering,
    setIsFiltering,
    filterByUser,
    fetchConfig,
    setFetchConfig,
    filterValue,
    setFilterValue,
    setIsDataFetching
  } = useMovimientos()

  const { format } = useRut('')
  const [shouldReset, setShouldReset] = useState(false)

  const Icon = {
    pollos: <PolloIcon bgcolor='rgba(232, 119, 34, 0.1)' />,
    pavos: <PavoIcon bgcolor='rgba(232, 119, 34, 0.1)' />,
    cerdos: <CerdoIcon bgcolor='rgba(232, 119, 34, 0.1)' />,
    planta: <AlimentoIcon bgcolor='rgba(232, 119, 34, 0.1)' />
  }

  const openFilterDrawer = () => {
    setShowDrawer(true)
  }

  /**
   * @description Handler filters with search or paginator
   * @param {*} Data that to belong to values to filter or data from paginator filter
   */

  const handleFilterValues = (data) => {
    switch (data.remove) {
      case true:
        setIsFiltering(false)

        setFetchConfig({
          ...fetchConfig,
          offset: 1,
          limit: 10
        })
        setFilterValue({
          area: filterByUser?.area ?? [],
          startDt: undefined,
          endDt: undefined,
          status: [],
          keyword: '',
          sector: filterByUser?.sector ?? [],
          porterias: filterByUser?.porterias ?? [],
          plants: filterByUser?.plants ?? []
        })
        setFilter({
          area: filterByUser?.area ?? [],
          startDt: undefined,
          endDt: undefined,
          status: [],
          keyword: '',
          sector: filterByUser?.sector ?? [],
          porterias: filterByUser?.porterias ?? [],
          plants: filterByUser?.plants ?? []
        })
        // handleFilters(filterValue, fetchConfig);
        break
      default:
        setFilterValue(data)
        setFilter(data)
        setIsFiltering(data.isFilter ?? true)
        setFetchConfig({
          ...fetchConfig,
          offset: 1,
          limit: 10
        })

        setShowDrawer(false)
        break
    }
  }

  const onClose = () => {
    setShowDrawer(false)
  }

  const formatDate = (dateTimestamp) => {
    let date = new Date()
    date.setTime(dateTimestamp)
    return moment(date).format('DD/MM/YYYY [a las] HH:mm [hrs]')
  }

  const handleResetFilters = () => {
    setSearchValue('')
    setFetchConfig({
      ...fetchConfig,
      offset: 1,
      limit: 10
    })
    setShouldReset(true)
    setFilterValue({
      area: filterByUser?.area ?? [],
      startDt: undefined,
      endDt: undefined,
      status: [],
      keyword: '',
      sector: filterByUser?.sector ?? [],
      porterias: filterByUser?.porterias ?? [],
      plants: filterByUser?.plants ?? []
    })
    setFilter({
      area: filterByUser?.area ?? [],
      startDt: undefined,
      endDt: undefined,
      status: [],
      keyword: '',
      sector: filterByUser?.sector ?? [],
      porterias: filterByUser?.porterias ?? [],
      plants: filterByUser?.plants ?? []
    })
    setIsLoading(true)
  }

  const statusCodes = {
    1: 'Aprobado',
    2: 'Denegado',
    3: 'Pendiente',
    4: 'Aprobado',
    5: 'Denegado',
    7: 'Aprobado',
    8: 'Denegado',
    9: 'Finalizado',
    10: 'Aprobado'
  }

  const columns = [
    {
      title: 'Identificador',
      fixed: true,
      render: (record) => {
        if (record) {
          const hasVerifyCode =
            record?.has_code_rut_verify === 'true' ||
            record?.user?.person?.has_code_rut_verify === 'true'
          const rutValue = hasVerifyCode ? record.rut + 'K' : record.rut
          const isPassport = record?.passport !== null ? 0 : 1
          const rutOrPassport = isPassport === 0 ? record.passport : rutValue
          if (VER_MOV_USUARIOS) {
            return rutOrPassport ? (
              <Link
                state={{
                  identificador: rutOrPassport,
                  type_identificador: isPassport
                }}
                to='/registro-movimientos/movimientos-por-usuario'
              >
                {isPassport === 0 ? record.passport : format(rutValue)}
              </Link>
            ) : (
              'No posee identificador'
            )
          }
          return <>{isPassport === 0 ? record.passport : format(rutValue)}</>
        }
      }
    },
    {
      title: 'Nombre',
      // width: "10%",
      editable: true,
      //   sorter: (a, b) => a.asociates - b.asociates,
      //   sortDirections: ["descend", "ascend"],
      render: (record) => capitalize(record.name)
    },
    {
      title: 'Área',
      // width: windowWidth > 426 ? "15%" : "10%",
      editable: true,
      render: (record) => {
        const name = record.area.toLowerCase().split(' ')

        return Icon[name[0]]
      }
      // Icon[record.area.toLowerCase()],
    },
    {
      title: 'Instalación',
      // width: windowWidth > 426 ? "20%" : "10%",
      editable: true,
      render: (record) => capitalize(record.sector ?? '-')
    },
    {
      title: 'Fecha ingreso',
      // width: windowWidth > 426 ? "15%" : "10%",
      editable: true,
      render: (record) =>
        record.entryAt ? formatDate(record.entryAt) : 'Registro Pendiente'
    },
    {
      title: 'Fecha salida',
      // width: windowWidth > 426 ? "20%" : "10%",
      editable: true,
      render: (record) => (record.exitAt ? formatDate(record.exitAt) : '-')
    },
    {
      title: 'Estado',
      // width: "15%",
      editable: false,
      render: (record) => (
        <span
          className={`statusCodeMove status${statusCodes[record?.status?.id]}`}
        >
          {statusCodes[record?.status?.id]}
        </span>
      )
    },

    // {
    //   title: "Indicadores",
    //   // width: "15%",
    //   editable: false,
    //   render: (record) => record.request_id,
    // },
    {
      title: 'Motivo',
      // width: "15%",
      editable: false,
      render: (record) => capitalize(record?.reason)
    }
  ]

  const handlePaginationChange = ({ current, pageSize }, _) => {
    setFetchConfig({
      ...fetchConfig,
      limit: pageSize,
      offset: current
    })
  }

  return (
    <div className='tabla-movimientos-container'>
      <div className='live-request'>
        <div
          className='request-btn'
          onClick={() => handleFilterValues({ remove: true })}
        >
          <RedoOutlined /> Live
        </div>
      </div>
      <div className='filter_container'>
        <div className='filters'>
          <div className='mobile-filters'>
            <CustomSearch
              loading={isSearching || isDataFetching}
              disabled={isSearching || isDataFetching}
              value={searchValue}
              onSearch={(value) => {
                handleFilterValues({
                  ...filterValue,
                  keyword: searchValue,
                  isFilter: true
                })
              }}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div className='filter-btn'>
            <SecondaryButton onButtonClick={openFilterDrawer} width={90}>
              Filtros
            </SecondaryButton>
          </div>
          {isFiltering ? (
            <PrimaryButton
              className='mobile-filters'
              onButtonClick={handleResetFilters}
            >
              <CloseOutlined />
            </PrimaryButton>
          ) : null}
        </div>
      </div>
      <Form form={form} component={false}>
        <Table
          loading={isDataFetching || isSearching}
          columns={columns}
          dataSource={tableData}
          scroll={{ x: 'max-content' }}
          pagination={{
            pageSize: fetchConfig.limit,
            current: fetchConfig.offset,
            total: total,
            showTotal: (total, range) =>
              `${range[0]} a ${range[1]} de ${total} resultados`
          }}
          onChange={handlePaginationChange}
        />
      </Form>
      <FiltroTablaMovimientos
        onClose={onClose}
        visible={showDrawer}
        handleFilterValues={handleFilterValues}
        isSearching={isSearching}
        isDataFetching={isDataFetching}
        searchValue={searchValue}
        shouldReset={shouldReset}
        setShouldReset={setShouldReset}
        setIsFiltering={setIsFiltering}
        filterByUser={filterByUser}
        setShowDrawer={setShowDrawer}
        setIsDataFetching={setIsDataFetching}
        setLoadingTarget={setIsLoading}
      />
    </div>
  )
}

export default TablaMovimientosGenerales
