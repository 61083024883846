import React, { useContext } from 'react'
import { Routes, Route, useNavigate, Outlet, Navigate } from 'react-router-dom'
import axios from 'axios'
import '../styles/base.scss'
import 'antd/dist/antd.min.css'
import 'antd/dist/antd.less'
import AboutPage from '../pages/About'
import Home from '../pages/Inicio'
import { AppContext, AppProvider } from './AppContext'
import Navbar from '../components/layout/Navbar'
import Dashboard from '../pages/RegistroMovimientos'
import Users from '../pages/Usuarios'
import Solicitudes from '../pages/Solicitudes'
import Piramides from '../pages/Piramides'
import NochesVacioContainer from 'components/NochesVacioContainer'
import Auth from 'pages/Auth'
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated
} from '@azure/msal-react'
import { SWRConfig } from 'swr'
import EditarCerdos from 'pages/FormularioCerdos/Editar'
import EditarPollos from 'pages/FormularioPollos/Editar'
import EditarPavos from 'pages/FormularioPavos/Editar'
import CrearRol from 'pages/Roles/CrearRol'
import EditarRol from 'pages/Roles/EditarRol'
import CreateUser from 'pages/CrearUsuario/CrearUsuario'
import Permisos from 'pages/Usuarios/Permisos'
import VerDetalleSolicitud from 'components/TableContainer/Solicitudes/verDetalleSolicitud'
import AsignarRol from 'pages/Roles/AsignarRol'
import EdicionSolicitud from 'pages/Solicitudes/editarSolicitud'
import EditarPlantaAlimentos from 'pages/PlantaAlimentos/Editar'
import VerDetalle from 'pages/Usuarios/VerDetalle'
import VerDetalleRol from 'pages/Roles/VerDetalle'
import Notificaciones from 'components/GestionNotificaciones/Notificaciones'
import RegistroMovimientos from 'pages/RegistroMovimientos/RegistroMovimientos'
import NuevaExepcion from 'components/TableContainer/Movimientos/NuevaExcepcion'
import MovimientosPorUsuario from 'pages/RegistroMovimientos/RegistroMovimientos'
import NuevaDeclaracion from 'components/DeclaracionJurada/NuevaDeclaracion'
import NuevaSolicitud from 'components/DeclaracionJurada/NuevaSolicitud'
import ConfirmacionFirmaDeclaracion from 'components/DeclaracionJurada/ConfirmacionFirmaDeclaracion'
import ErrorFirmaDeclaracion from 'components/DeclaracionJurada/ErrorFirmaDeclaracion'
import { CreatePyramid } from './formRoutes'
import EditarSector from 'pages/Sector/EditarSector'
import Error from 'pages/Error/Error'
import { CustomNavigationClient } from 'utils/NavigationClient'
import { MassiveUploadUsers } from 'pages/CargaMasivaUsuarios'
import { MassiveUploadProvider } from './MassiveUploadProvider'
import Reporteria from 'pages/Reporteria'
import { useUsuarioByEmail } from 'services/autenticacion/usuarios/get'
import { Spin } from 'antd'
import Cuarentenas from '../pages/Cuarentenas'
import NuevaCuarentena from 'components/Cuarentenas/NuevaCuarentena'
import VerDetalleCuarentena from 'pages/Cuarentenas/VerDetalleCuarentena'
import RedirectLoginView from 'pages/Inicio/RedirectLoginView'
import EditarCuarentena from 'components/Cuarentenas/Editar/EditarCuarentena'
import ExistenteCuarentena from 'components/Cuarentenas/Editar/ExistenteCuarentena'
import Declaraciones from 'pages/Declaraciones'
import VerDetalleDelaracion from 'components/TableContainer/Declaraciones/verDetalleDeclaracion'
import NuevaSolicitudExterno from 'components/DeclaracionJurada/VisitaExterna/NuevaSolicitudExterno'
import ConfirmacionFirmaDeclaracionExterno from 'components/DeclaracionJurada/VisitaExterna/ConfirmacionFirmaDeclaracionExterno'
import ErrorFirmaDeclaracionExterno from 'components/DeclaracionJurada/VisitaExterna/ErrorFirmaDeclaracionExterno'

const fetcher = async (url) => {
  return axios.get(url).then((res) => {
    if (res.statusText !== 'OK') {
      const error = new Error('** An error occurred while fetching the data. ')
      // Attach extra info to the error object.
      error.info = res
      error.status = res.status
      throw error
    }
    return res.data
  })
}

function PrivateRoutes() {
  return (
    <AuthenticatedTemplate>
      <Navbar />
      <Outlet />
    </AuthenticatedTemplate>
  )
}

function AnonymousRoutes() {
  const isAuthenticated = useIsAuthenticated()

  return isAuthenticated ? (
    <Navigate to='/first-login' replace />
  ) : (
    <UnauthenticatedTemplate>
      <Outlet />
    </UnauthenticatedTemplate>
  )
}

function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate()
  const navigationClient = new CustomNavigationClient(navigate)
  pca.setNavigationClient(navigationClient)

  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        fetcher
      }}
    >
      <MsalProvider instance={pca}>
        <MassiveUploadProvider>
          <AppProvider>
            <ApplicationRoutes />
          </AppProvider>
        </MassiveUploadProvider>
      </MsalProvider>
    </SWRConfig>
  )
}

function ApplicationRoutes() {
  const { isLoadingPermissions, envConfig } = useContext(AppContext)

  const { data, isLoading } = useUsuarioByEmail(envConfig)

  axios.interceptors.request.use(
    (config) => {
      config.headers = {
        ...config.headers,
        ...(data && {
          'user-id': JSON.stringify({
            userId: data?.id,
            email: data?.email
          })
        }),
        'Accept-Language': 'es-ES,es;'
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  if (isLoading || isLoadingPermissions) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spin size='large' />
      </div>
    )
  }

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path='/auth' element={<Auth />} />
        <Route path='/first-login' element={<RedirectLoginView />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/usuarios' element={<Users />} />
        <Route path='usuarios/:id/asignar-rol' element={<AsignarRol />} />
        <Route path='usuarios/:id/ver-detalle' element={<VerDetalle />} />
        <Route path='usuarios/:id/permisos' element={<Permisos />} />
        <Route path='/solicitudes' element={<Solicitudes />} />
        <Route path='/piramides' element={<Piramides />} />
        <Route path='/noches-de-vacio' element={<NochesVacioContainer />} />
        <Route path='/piramides/crear/:pyramid' element={<CreatePyramid />} />
        <Route path='/piramides/editar/cerdos' element={<EditarCerdos />} />
        <Route path='/piramides/editar/pollos' element={<EditarPollos />} />
        <Route path='/piramides/editar/pavos' element={<EditarPavos />} />
        <Route path='/crear-rol' element={<CrearRol />} />
        <Route path='rol/editar/:id' element={<EditarRol />} />
        <Route path='rol/:id/ver-detalle' element={<VerDetalleRol />} />
        <Route path='usuarios/crear-nuevo' element={<CreateUser />} />
        <Route path='/reporterias' element={<Reporteria />} />
        <Route
          path='/solicitud/editarSolicitud'
          element={<EdicionSolicitud />}
        />
        {/* <Route
          path="/solicitudes/nueva-solicitud"
          element={<NuevaSolicitud />}
        /> */}

        <Route path='/solicitud/VerDetalle' element={<VerDetalleSolicitud />} />

        <Route
          path='/piramides/planta-alimentos/editar/:id'
          element={<EditarPlantaAlimentos />}
        />
        <Route path='/notificaciones' element={<Notificaciones />} />
        <Route path='/registro-movimientos' element={<RegistroMovimientos />} />
        <Route
          path='/registro-movimientos/nueva-excepcion'
          element={<NuevaExepcion />}
        />
        <Route
          path='/registro-movimientos/movimientos-por-usuario'
          element={<MovimientosPorUsuario />}
        />
        <Route
          path='/registro-movimientos/nueva-declaracion'
          element={<NuevaDeclaracion />}
        />
        <Route
          path='/piramides/editar/sector/:specie/:id'
          element={<EditarSector />}
        />
        <Route path='/error' element={<Error />} />

        <Route
          exact
          path='/solicitudes/carga-masiva-usuarios'
          element={<MassiveUploadUsers />}
        />
        <Route path='/cuarentenas' element={<Cuarentenas />} />
        <Route
          path='cuarentenas/:id/ver-detalle'
          element={<VerDetalleCuarentena />}
        />
        <Route
          path='/cuarentenas/nueva-cuarentena'
          element={<NuevaCuarentena />}
        />
        <Route
          path='/cuarentenas/editar-cuarentena/:id'
          element={<ExistenteCuarentena />}
        />
        <Route path='/declaraciones' element={<Declaraciones />} />
        <Route
          path='/declaraciones/nueva-declaracion'
          element={<NuevaSolicitud />}
        />
        <Route
          path='/declaraciones/ConfirmacionFirmaDeclaracion'
          element={<ConfirmacionFirmaDeclaracion />}
        />
        <Route
          path='/declaraciones/ErrorFirmaDeclaracion'
          element={<ErrorFirmaDeclaracion />}
        />
        <Route
          path='/declaraciones/VerDetalle'
          element={<VerDetalleDelaracion />}
        />
      </Route>
      <Route element={<AnonymousRoutes />}>
        <Route path='/' element={<Home />} />
        <Route
          path='/declaraciones/visita-externa/:token'
          element={<NuevaSolicitudExterno />}
        />
        <Route
          path='/declaraciones/visita-externa/ConfirmacionFirmaDeclaracion'
          element={<ConfirmacionFirmaDeclaracionExterno />}
        />
        <Route
          path='/declaraciones/visita-externa/ErrorFirmaDeclaracion'
          element={<ErrorFirmaDeclaracionExterno />}
        />
      </Route>
    </Routes>
  )
}

export default App
